import {Icon, IconSpec, TextLink} from '@kensho/neo'

interface GraphicElementProps {
  icon: IconSpec
  heading: string
  description: string
  href: string
  label: string
}

export default function GraphicElement(props: GraphicElementProps): React.ReactNode {
  const {icon, heading, description, href, label} = props

  return (
    <div className="flex min-w-0 flex-1 gap-6 px-2 sm:max-w-64 sm:flex-col sm:items-center sm:gap-2 sm:px-0">
      <span className="text-gray-500">
        <Icon icon={icon} size="large" />
      </span>
      <div className="flex flex-1 flex-col items-start gap-2 sm:items-center">
        <div className="flex-1 sm:text-center">
          <h3 className="mb-1 font-bold">{heading}</h3>
          <p>{description}</p>
        </div>
        <TextLink href={href}>{label} ›</TextLink>
      </div>
    </div>
  )
}
