import {z} from 'zod'

/** Reference to a top-level definition of an annotation at the slice or token level */
const AnnotationRefSchema = z.object({
  id: z.string(),
})

const TokenSchema = z.object({
  transcript: z.string(),
  startMs: z.number(),
  durationMs: z.number(),
  accuracy: z.number(),
  /** from initial model output, unused */
  alignSuccess: z.boolean().optional(),
  /** indicates that the startMs and durationMs have been aligned to the audio */
  aligned: z.boolean().optional(),
  isPartial: z.boolean().optional(),
  isRecent: z.boolean().optional(),
  annotations: z.array(AnnotationRefSchema).optional(),
})

const SliceSchema = z.object({
  speakerId: z.number(),
  speakerAccuracy: z.number(),
  transcript: z.string(),
  startMs: z.number(),
  durationMs: z.number(),
  accuracy: z.number(),
  tokenMeta: z.array(TokenSchema),
  isPartial: z.boolean().optional(),
  annotations: z.array(AnnotationRefSchema).optional(),
})

const AddPatchSchema = z.object({
  op: z.literal('add'),
  path: z.string(),
  value: z.any(),
})

const ReplacePatchSchema = z.object({
  op: z.literal('replace'),
  path: z.string(),
  value: z.any(),
})

const RemovePatchSchema = z.object({
  op: z.literal('remove'),
  path: z.string(),
})

const JSONPatchSchema = z.discriminatedUnion('op', [
  AddPatchSchema,
  ReplacePatchSchema,
  RemovePatchSchema,
])

export type JSONPatch = z.infer<typeof JSONPatchSchema>

const TokenSelectionNodeSchema = z.object({
  type: z.literal('token'),
  sliceIndex: z.number(),
  tokenIndex: z.number(),
  textOffset: z.number(),
})
const TokenSpaceSelectionNodeSchema = z.object({
  type: z.literal('token-space'),
  sliceIndex: z.number(),
  tokenIndex: z.number(),
  textOffset: z.number(),
})

const TranscriptSelectionNodeSchema = z.discriminatedUnion('type', [
  TokenSelectionNodeSchema,
  TokenSpaceSelectionNodeSchema,
])

export const MultiplayerServerMessageSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('authenticated'),
    payload: z.object({
      sessionId: z.string(),
    }),
  }),
  z.object({
    type: z.literal('presence-add'),
    payload: z.object({
      clientId: z.string(),
      sessionId: z.string(),
      clientName: z.string(),
    }),
  }),
  z.object({
    type: z.literal('presence-remove'),
    payload: z.object({
      sessionId: z.string(),
    }),
  }),
  z.object({
    type: z.literal('error'),
    payload: z.object({
      code: z.string(),
      title: z.string(),
      detail: z.string(),
    }),
  }),
  z.object({
    type: z.literal('patch'),
    payload: z.object({
      patches: z.array(JSONPatchSchema),
      sessionId: z.string(),
    }),
  }),
  z.object({
    type: z.literal('cursor-update'),
    payload: z.object({
      start: TranscriptSelectionNodeSchema.nullable(),
      end: TranscriptSelectionNodeSchema.nullable(),
      sessionId: z.string(),
    }),
  }),
  z.object({
    type: z.literal('reconnect'),
    payload: z.object({
      detail: z.string(),
    }),
  }),
])

export type MultiplayerServerMessage = z.infer<typeof MultiplayerServerMessageSchema>

export const MultiplayerClientMessageSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('authenticate'),
    payload: z.object({
      token: z.string(),
    }),
  }),
  z.object({
    type: z.literal('patch'),
    payload: z.object({
      patches: z.array(JSONPatchSchema),
      sessionId: z.string(),
    }),
  }),
  z.object({
    type: z.literal('cursor-update'),
    payload: z.object({
      start: TranscriptSelectionNodeSchema.nullable(),
      end: TranscriptSelectionNodeSchema.nullable(),
      sessionId: z.string(),
    }),
  }),
])

export type MultiplayerClientMessage = z.infer<typeof MultiplayerClientMessageSchema>

export const HistoricalTranscriptSearchParamSchema = z.object({
  search: z.string(),
  sort: z.enum(['name', 'started_on', 'updated_on', 'status', 'media_type', 'media_duration']),
  direction: z.enum(['ascending', 'descending']),
  page: z.string(),
})

export type HistoricalTranscriptSearchParams = z.infer<typeof HistoricalTranscriptSearchParamSchema>
export const RealtimeServerMessageSchema = z.discriminatedUnion('message', [
  z.object({
    message: z.literal('Authenticated'),
  }),
  z.object({
    message: z.literal('TranscriptionStarted'),
    requestId: z.string(),
  }),
  z.object({
    message: z.literal('TranscriptionResumed'),
    requestId: z.string(),
    sequenceNumber: z.number(),
  }),
  z.object({
    message: z.literal('DataAdded'),
    sequenceNumber: z.number(),
  }),
  z.object({
    message: z.literal('AddTranscript'),
    transcript: SliceSchema,
  }),
  z.object({
    message: z.literal('EndOfTranscript'),
  }),
  z.object({
    message: z.literal('Error'),
    type: z.string(),
    reason: z.string(),
  }),
])
export type RealtimeServerMessage = z.infer<typeof RealtimeServerMessageSchema>

export const RealtimeClientMessageSchema = z.discriminatedUnion('message', [
  z.object({
    message: z.literal('Authenticate'),
    token: z.string(),
  }),
  z.object({
    message: z.literal('StartTranscription'),
    audioFormat: z.object({
      type: z.string(),
      encoding: z.string(),
      sampleRateHz: z.number(),
      numChannels: z.number(),
    }),
    hotwords: z.array(z.string()).optional(),
    features: z
      .object({
        allowPartials: z.boolean().optional(),
      })
      .optional(),
  }),
  z.object({
    message: z.literal('ResumeTranscription'),
    requestId: z.string(),
    token: z.string(),
  }),
  z.object({
    message: z.literal('AddData'),
    audio: z.string(),
    sequenceNumber: z.number(),
  }),
  z.object({
    message: z.literal('EndOfStream'),
    lastSequenceNumber: z.number(),
  }),
])
export type RealtimeClientMessage = z.infer<typeof RealtimeClientMessageSchema>
