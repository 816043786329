import {use, useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {throttle} from 'lodash-es'
import {useLogger} from '@kensho/lumberjack'

import useGetTranscript from '../api/useGetTranscript'
import {TIMELINE_HEIGHT} from '../core/transcription/constants'
import useObjectURL from '../hooks/useObjectURL'
import useRefState from '../hooks/useRefState'
import TranscriptContext from '../providers/TranscriptContext'

interface VideoProps extends React.HTMLProps<HTMLVideoElement> {
  subtitlesSrc?: string
  mediaSrc: string
  showSubtitles?: boolean
  ref: React.Ref<HTMLVideoElement>
}

export default function Video(props: VideoProps): React.ReactNode {
  const {children, mediaSrc, showSubtitles, ref, ...rest} = props
  const {metadata, transcript} = use(TranscriptContext)
  const log = useLogger()
  const {transcriptId} = useParams()
  const [, getTranscript] = useGetTranscript()
  const [subtitlesBlob, setSubtitlesBlob] = useState<Blob>()
  const subtitlesSrc = useObjectURL(subtitlesBlob)
  const [trackEle, setTrackEle] = useState<HTMLTrackElement | null>(null)
  const [videoEle, setVideoEle] = useState<HTMLVideoElement | null>(null)
  const refState = useRefState<HTMLVideoElement>(ref, setVideoEle)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [cues, setCues] = useState<VTTCue[]>([])

  // download subtitles (VTT)
  // once at the beginning and then periodically when the transcript is edited
  useEffect(() => {
    if (!transcriptId || metadata?.status !== 'complete') return undefined

    let current = true
    const fetchSubtitles = throttle(
      async (): Promise<void> => {
        try {
          const vtt = await getTranscript({transcriptId, accept: 'text/vtt'})
          if (current) setSubtitlesBlob(vtt as Blob)
        } catch (e) {
          // ignore failure, subtitles won't be available
          log.error(e as Error)
        }
      },
      10000,
      {leading: false},
    )

    fetchSubtitles()

    return () => {
      current = false
      fetchSubtitles.cancel()
    }
  }, [metadata?.status, getTranscript, transcript, transcriptId, log])

  // hide native subtitles except in fullscreen
  useEffect(() => {
    const track = videoEle?.textTracks[0]
    if (track) track.mode = isFullscreen ? 'showing' : 'hidden'
  }, [videoEle, subtitlesSrc, isFullscreen])

  // handle fullscren toggle
  useEffect(() => {
    const onFullscreenChange = (): void => {
      setIsFullscreen(!!document.fullscreenElement)
    }

    if (!videoEle) return undefined

    videoEle.addEventListener('fullscreenchange', onFullscreenChange)

    return () => {
      videoEle.removeEventListener('fullscreenChange', onFullscreenChange)
    }
  }, [videoEle])

  // update custom cues state
  useEffect(() => {
    const onCueChange = (event: Event): void => {
      setCues([...(((event.target as HTMLTrackElement).track.activeCues || []) as VTTCue[])])
    }

    if (!trackEle) return undefined

    trackEle.addEventListener('cuechange', onCueChange)

    return () => {
      trackEle.removeEventListener('cuechange', onCueChange)
    }
  }, [trackEle])

  return (
    <div className="relative h-full w-full">
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        data-testid="floating-video"
        className="h-full w-full"
        // unique key to force re-render when src changes so onLoadedMetadata is called
        key={mediaSrc}
        ref={refState}
        {...rest}
      >
        {children}
        {subtitlesSrc && (
          <track
            default
            ref={(r) => setTrackEle(r)}
            kind="captions"
            src={subtitlesSrc}
            label="Generated transcript"
          />
        )}
      </video>
      {/* custom subtitles rendering */}
      {showSubtitles && subtitlesSrc && !!cues.length && (
        <div
          data-testid="video-captions"
          className="absolute min-h-9 w-full text-center text-base text-white"
          style={{bottom: `${TIMELINE_HEIGHT + 5}px`}}
        >
          <div className="mx-auto inline-block bg-black bg-opacity-80 p-2">
            {cues.map((cue) => {
              const cueText = cue.text.replace(/<.+?>/g, '')
              return (
                // can't use cue.id for the key because we're not generating identifiers in VTT
                <p key={`${cue.startTime}-${cue.endTime} ${cueText}`} className="my-0.5">
                  {cueText}
                </p>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}
