import {createContext, use, useMemo, useState} from 'react'

interface TranscriptNameContextInterface {
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
  isEditingMap: {[key: string]: boolean}
  setIsEditingMap: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean
    }>
  >
}
const TranscriptNameContext = createContext<TranscriptNameContextInterface>({
  isEditing: false,
  setIsEditing: () => {},
  isEditingMap: {},
  setIsEditingMap: () => {},
})

interface TranscriptNameContextProviderProps {
  children: React.ReactNode
}

export function useTranscriptNameContext(id: string): {
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
} {
  const {isEditingMap, setIsEditingMap} = use(TranscriptNameContext)
  const isEditing = isEditingMap[id] || false
  function setIsEditing(priorIsEditing: boolean): void {
    setIsEditingMap((prev) => ({
      ...prev,
      [id]: priorIsEditing,
    }))
  }
  return {isEditing, setIsEditing}
}

export function TranscriptNameContextProvider({
  children,
}: TranscriptNameContextProviderProps): React.ReactNode {
  const [isEditing, setIsEditing] = useState(false)
  const [isEditingMap, setIsEditingMap] = useState<{[key: string]: boolean}>({})

  const value = useMemo(
    () => ({isEditing, setIsEditing, isEditingMap, setIsEditingMap}),
    [isEditing, setIsEditing, isEditingMap, setIsEditingMap],
  )
  return <TranscriptNameContext value={value}>{children}</TranscriptNameContext>
}
