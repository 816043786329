import {ButtonLink} from '@kensho/neo'

import useObjectURL from '../../../hooks/useObjectURL'

export default function DownloadMediaFileButton({
  mediaFile,
  name = 'audio',
}: {
  mediaFile?: File
  name?: string
}): React.ReactNode {
  const url = useObjectURL(mediaFile)

  return (
    <ButtonLink
      href={url || ''}
      download={name}
      aria-label="Download media"
      icon="ArrowDownTrayIcon"
      minimal
      intent="primary"
      disabled={!mediaFile}
    >
      Download media
    </ButtonLink>
  )
}
