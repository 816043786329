import {useLogger} from '@kensho/lumberjack'
import {Button} from '@kensho/neo'
import {ReactNode, use, useState} from 'react'
import {ZodError} from 'zod'

import AudioInputDeviceSelect from '../../../components/AudioInputDeviceSelect'
import ErrorDialog from '../../../components/ErrorDialog'
import useTypedWebSocket from '../../../hooks/useTypedWebSocket'
import SiteAnalyticsContext from '../../../providers/SiteAnalyticsContext'
import TranscriptContext from '../../../providers/TranscriptContext'
import UserContext from '../../../providers/UserContext'
import {
  RealtimeClientMessage,
  RealtimeClientMessageSchema,
  RealtimeServerMessage,
  RealtimeServerMessageSchema,
} from '../../../types/schemas'
import {
  RealtimeTranscriptionConfiguration,
  ScribeError,
  TranscriptionConfiguration,
} from '../../../types/types'
import getDefaultTranscript from '../../../utils/getDefaultTranscript'
import parseError from '../../../utils/parseError'
import {REALTIME_NUM_CHANNELS, REALTIME_TRANSCRIPTION_SAMPLE_RATE} from '../constants'

import {FormState} from './FormState'
import PartialTranscripts from './PartialTranscripts'
import TranscriptName from './TranscriptName'

type RealtimeSubmissionStatus = 'idle' | 'starting'

interface RealtimeConfigFormProps {
  transcriptionConfiguration: RealtimeTranscriptionConfiguration
  setTranscriptionConfiguration: React.Dispatch<React.SetStateAction<TranscriptionConfiguration>>
  audioInputDeviceId?: string
  onAudioInputDeviceChange: (nextAudioDeviceInfoId?: string) => void
  resetAudioAndTranscript: () => void
  setTranscriptId: (nextTranscriptId?: string) => void
  formState: FormState
  setFormState: React.Dispatch<React.SetStateAction<FormState>>
}

export default function RealtimeConfigForm(props: RealtimeConfigFormProps): ReactNode {
  const {
    resetAudioAndTranscript,
    transcriptionConfiguration,
    setTranscriptionConfiguration,
    setTranscriptId,
    audioInputDeviceId,
    onAudioInputDeviceChange,
    formState,
    setFormState,
  } = props
  const log = useLogger()
  const analytics = use(SiteAnalyticsContext)
  const {user} = use(UserContext)
  const {dispatch: transcriptContextDispatch} = use(TranscriptContext)
  const [scribeError, setScribeError] = useState<ScribeError>()
  const [status, setStatus] = useState<RealtimeSubmissionStatus>('idle')

  // when user submits form open a realtime websocket connection and start the transcription
  // once the transcription id is received, close the connection and proceed to next stage where
  // RealtimeTranscriber will reconnect and resume with that transcription id
  useTypedWebSocket({
    url: `${window.location.protocol === 'https:' ? 'wss://' : 'ws://'}${window.location.host}/ws`,
    clientMessageSchema: RealtimeClientMessageSchema,
    serverMessageSchema: RealtimeServerMessageSchema,
    onValidationError: (error: ZodError, message: unknown, isClientMessage: boolean): void => {
      log.error(error, {message: `${JSON.stringify(message)}`, isClientMessage})
      setScribeError({type: 'realtimeStartError'})
    },
    onMessage: (
      message: RealtimeServerMessage,
      sendMessage: (message: RealtimeClientMessage) => void,
    ): boolean => {
      switch (message.message) {
        case 'Authenticated':
          sendMessage({
            message: 'StartTranscription',
            audioFormat: {
              type: 'RAW',
              encoding: 'pcm_s16le',
              sampleRateHz: REALTIME_TRANSCRIPTION_SAMPLE_RATE,
              numChannels: REALTIME_NUM_CHANNELS,
            },
            hotwords: [...(transcriptionConfiguration.hotwords || [])],
            features: {allowPartials: !!transcriptionConfiguration.partialTranscripts},
          })
          break
        case 'TranscriptionStarted':
          setTranscriptId(message.requestId)
          transcriptContextDispatch({
            type: 'setTranscript',
            transcript: getDefaultTranscript(),
          })
          transcriptContextDispatch({type: 'setStage', stage: 'TRANSCRIPTION'})
          break
        case 'Error':
          setScribeError(parseError(message))
          setStatus('idle')
          break
        default:
          break
      }
      return false
    },
    onClose: (e: CloseEvent): boolean => {
      // code 1000 is a clean connection close, all else are connection errors
      if (e.code !== 1000) {
        log.error('Realtime start connection error', {code: e.code, reason: e.reason})
        setScribeError({type: 'realtimeStartError'})
      }
      setStatus('idle')
      return false
    },
    onOpen: (sendMessage: (message: RealtimeClientMessage) => void): void => {
      if (!user?.token) {
        setScribeError({type: 'unauthenticated'})
        return
      }
      sendMessage({message: 'Authenticate', token: user?.token})
    },
    shouldConnect: !scribeError && status === 'starting',
  })

  return (
    <div className="flex flex-col gap-10">
      <ErrorDialog
        isOpen={!!scribeError}
        error={scribeError}
        onClose={() => setScribeError(undefined)}
      />
      <div className="text-lg font-semibold">Start a new recording</div>
      <TranscriptName
        error={formState.errors.transcriptName}
        setFormState={setFormState}
        transcriptName={formState.transcriptName}
      />
      <AudioInputDeviceSelect
        value={audioInputDeviceId}
        onChange={onAudioInputDeviceChange}
        formError={formState.errors.audioInputDevice}
        setFormState={setFormState}
      />
      <PartialTranscripts
        transcriptionConfiguration={transcriptionConfiguration}
        setTranscriptionConfiguration={setTranscriptionConfiguration}
      />
      <div className="-my-5 flex justify-end gap-4">
        <Button
          onClick={() => {
            analytics.sendEvent('cancel_realtime')
            resetAudioAndTranscript()
          }}
        >
          Cancel
        </Button>
        <Button
          intent="primary"
          disabled={status === 'starting' || Object.values(formState.errors).some((error) => error)}
          onClick={() => {
            setTranscriptionConfiguration({
              ...transcriptionConfiguration,
              name: formState.transcriptName,
              hotwords: formState.hotwords,
            })
            analytics.sendEvent('start_realtime_transcription')
            setScribeError(undefined)
            setStatus('starting')
          }}
        >
          {status === 'idle' ? 'Start Recording' : 'Starting…'}
        </Button>
      </div>
    </div>
  )
}
