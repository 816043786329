import {TextLink} from '@kensho/neo'

import {URLS, PRODUCT} from '../constants'

export default function Footer(): React.ReactNode {
  return (
    <footer className="mx-4 my-3 flex h-12 w-auto max-w-5xl items-center justify-between lg:mx-auto lg:my-3 lg:w-full">
      <div className="shrink grow-0 text-center">
        <p className="my-0">
          Have questions?{' '}
          <TextLink href={URLS.CONTACT_FORM}>Contact the {PRODUCT.NAME} Team</TextLink>
        </p>
      </div>
      <div className="shrink grow-0 text-center">
        <TextLink href={URLS.TERMS_OF_USE}>Web Terms</TextLink>
        {'  '}|{'  '}
        <TextLink href={URLS.TERMS_OF_SERVICE}>Service Terms</TextLink>
        {'  '}|{'  '}
        <TextLink href={URLS.PRIVACY_POLICY}>Privacy</TextLink>
      </div>
    </footer>
  )
}
