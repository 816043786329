import {use, useEffect} from 'react'

import {MultiplayerContext, MultiplayerContextType} from '../providers/MultiplayerProvider'
import {MultiplayerServerMessage} from '../types/schemas'

export default function useMultiplayerContext(
  receiveMessage?: (message: MultiplayerServerMessage) => void,
): MultiplayerContextType {
  const context = use(MultiplayerContext)
  if (!context) {
    throw new Error('useMultiplayerContext must be used within a MultiplayerProvider')
  }
  const {registerReceiveMessageCallback} = context

  useEffect(() => {
    if (!receiveMessage) return undefined
    return registerReceiveMessageCallback(receiveMessage)
  }, [receiveMessage, registerReceiveMessageCallback])

  return context
}
