import clsx from 'clsx'
import {useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {isEqual} from 'lodash-es'

import assertNever from '../utils/assertNever'

type ResizeDirection =
  | 'north'
  | 'northeast'
  | 'east'
  | 'southeast'
  | 'south'
  | 'southwest'
  | 'west'
  | 'northwest'

interface Props {
  children?: React.ReactNode
  initialWidth?: number | string
  initialHeight?: number | string
  minHeight?: number
  minWidth?: number
  className?: string
  resize?: 'all' | 'vertical' | 'horizontal' | Partial<Record<ResizeDirection, boolean>>
  onResize?: (size: {width: number | string; height: number | string}) => void
  disabled?: boolean
  ref?: React.Ref<ResizableContainerRef>
}

export interface ResizableContainerRef {
  setSize: (size: {width?: number | string; height?: number | string}) => void
}

/**
 * Wraps its children in a block container that can be resized
 */
export default function ResizableContainer(props: Props): React.ReactNode {
  const {
    className,
    children,
    resize = 'all',
    initialWidth = 'auto',
    initialHeight = 'auto',
    minHeight = 0,
    minWidth = 0,
    onResize,
    disabled,
    ref,
  } = props
  const [width, setWidth] = useState(initialWidth)
  const [height, setHeight] = useState(initialHeight)
  const [allowedResizeDirections, setAllowedResizeDirections] = useState<
    Partial<Record<ResizeDirection, boolean>>
  >({
    north: true,
    northeast: true,
    east: true,
    southeast: true,
    south: true,
    southwest: true,
    west: true,
    northwest: true,
  })
  const [resizeDirection, setResizeDirection] = useState<ResizeDirection | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const onResizeRef = useRef<
    ((size: {width: number | string; height: number | string}) => void) | undefined
  >(onResize)
  const startingCursorOffsetRef = useRef({left: 0, top: 0})

  const onResizeMouseDown = useCallback(
    (event: React.MouseEvent, nextResizeDirection: ResizeDirection): void => {
      event.preventDefault()
      event.stopPropagation()

      setResizeDirection(nextResizeDirection)

      startingCursorOffsetRef.current = {left: event.clientX, top: event.clientY}
    },
    [],
  )

  useImperativeHandle(
    ref,
    () => ({
      setSize: ({width: nextWidth, height: nextHeight}) => {
        if (nextWidth !== undefined)
          setWidth(typeof nextWidth === 'string' ? nextWidth : Math.max(minWidth, nextWidth))
        if (nextHeight !== undefined)
          setHeight(typeof nextHeight === 'string' ? nextHeight : Math.max(minHeight, nextHeight))
      },
    }),
    [minHeight, minWidth],
  )

  useEffect(() => {
    let nextAllowedResizeDirections: Partial<Record<ResizeDirection, boolean>> = {}
    if (resize === 'all') {
      nextAllowedResizeDirections = {
        north: true,
        northeast: true,
        east: true,
        southeast: true,
        south: true,
        southwest: true,
        west: true,
        northwest: true,
      }
    } else if (resize === 'vertical') {
      nextAllowedResizeDirections = {north: true, south: true}
    } else if (resize === 'horizontal') {
      nextAllowedResizeDirections = {east: true, west: true}
    } else {
      nextAllowedResizeDirections = {...resize}
    }

    if (!isEqual(nextAllowedResizeDirections, allowedResizeDirections)) {
      setAllowedResizeDirections(nextAllowedResizeDirections)
    }
  }, [resize, allowedResizeDirections])

  useEffect(() => {
    onResizeRef.current = onResize
  }, [onResize])
  useEffect(() => {
    onResizeRef.current?.({width, height})
  }, [width, height])

  // reset size when disabled
  useEffect(() => {
    if (!disabled) return
    setWidth(initialWidth)
    setHeight(initialHeight)
  }, [initialWidth, initialHeight, disabled])

  // handle resize mouse events
  useEffect(() => {
    if (!resizeDirection) return undefined

    const onMouseMove = (event: MouseEvent): void => {
      if (!containerRef.current) return

      const clientRect = containerRef.current.getBoundingClientRect()
      let nextHeight = clientRect.height
      let nextWidth = clientRect.width

      switch (resizeDirection) {
        case 'north':
          nextHeight = Math.max(minHeight, clientRect.height + (clientRect.y - event.clientY))
          setHeight(nextHeight)
          break
        case 'northeast':
          nextHeight = Math.max(minHeight, clientRect.height + (clientRect.y - event.clientY))
          setHeight(nextHeight)

          nextWidth = Math.max(
            minWidth,
            clientRect.width + (event.clientX - (clientRect.x + clientRect.width)),
          )
          setWidth(nextWidth)
          break
        case 'east':
          setWidth(
            Math.max(
              minWidth,
              clientRect.width + (event.clientX - (clientRect.x + clientRect.width)),
            ),
          )
          break
        case 'southeast':
          setHeight(
            Math.max(
              minHeight,
              clientRect.height + (event.clientY - (clientRect.y + clientRect.height)),
            ),
          )

          setWidth(
            Math.max(
              minWidth,
              clientRect.width + (event.clientX - (clientRect.x + clientRect.width)),
            ),
          )
          break
        case 'south':
          setHeight(
            Math.max(
              minHeight,
              clientRect.height + (event.clientY - (clientRect.y + clientRect.height)),
            ),
          )
          break
        case 'southwest':
          setHeight(
            Math.max(
              minHeight,
              clientRect.height + (event.clientY - (clientRect.y + clientRect.height)),
            ),
          )

          nextWidth = Math.max(minWidth, clientRect.width + (clientRect.x - event.clientX))
          setWidth(nextWidth)
          break
        case 'west':
          nextWidth = Math.max(minWidth, clientRect.width + (clientRect.x - event.clientX))
          setWidth(nextWidth)
          break
        case 'northwest':
          nextHeight = Math.max(minHeight, clientRect.height + (clientRect.y - event.clientY))
          setHeight(nextHeight)

          nextWidth = Math.max(minWidth, clientRect.width + (clientRect.x - event.clientX))
          setWidth(nextWidth)
          break
        default:
          assertNever(resizeDirection)
      }
    }

    const onMouseUp = (event: MouseEvent): void => {
      event.preventDefault()
      event.stopPropagation()

      setResizeDirection(null)
    }

    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('mouseup', onMouseUp)

    return () => {
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('mouseup', onMouseUp)
    }
  }, [resizeDirection, minHeight, minWidth])

  return (
    <div ref={containerRef} className={clsx('relative', className)} style={{height, width}}>
      {children}

      {/* resize handles */}
      {!disabled && (
        <>
          {allowedResizeDirections.north && (
            <div
              className="absolute left-0 right-0 top-0 h-1 cursor-ns-resize"
              onMouseDown={(e) => onResizeMouseDown(e, 'north')}
            />
          )}
          {allowedResizeDirections.northeast && (
            <div
              className="absolute right-0 top-0 z-20 h-2 w-2 cursor-ne-resize"
              onMouseDown={(e) => onResizeMouseDown(e, 'northeast')}
            />
          )}
          {allowedResizeDirections.east && (
            <div
              className="absolute bottom-0 right-0 top-0 w-1 cursor-ew-resize"
              onMouseDown={(e) => onResizeMouseDown(e, 'east')}
            />
          )}
          {allowedResizeDirections.southeast && (
            <div
              className="absolute bottom-0 right-0 z-20 h-2 w-2 cursor-se-resize"
              onMouseDown={(e) => onResizeMouseDown(e, 'southeast')}
            />
          )}
          {allowedResizeDirections.south && (
            <div
              className="absolute bottom-0 left-0 right-0 h-1 cursor-ns-resize"
              onMouseDown={(e) => onResizeMouseDown(e, 'south')}
            />
          )}
          {allowedResizeDirections.southwest && (
            <div
              className="absolute bottom-0 left-0 z-20 h-2 w-2 cursor-sw-resize"
              onMouseDown={(e) => onResizeMouseDown(e, 'southwest')}
            />
          )}
          {allowedResizeDirections.west && (
            <div
              className="absolute bottom-0 left-0 top-0 w-1 cursor-ew-resize"
              onMouseDown={(e) => onResizeMouseDown(e, 'west')}
            />
          )}
          {allowedResizeDirections.northwest && (
            <div
              className="absolute left-0 top-0 z-20 h-2 w-2 cursor-nw-resize"
              onMouseDown={(e) => onResizeMouseDown(e, 'northwest')}
            />
          )}
        </>
      )}
    </div>
  )
}
