import {useCallback} from 'react'

/** provide a ref callback that assigns to both a ref and state value */
export default function useRefState<T>(
  ref: React.Ref<T>,
  setState: React.Dispatch<React.SetStateAction<T | null>>,
): (r: T | null) => void {
  const refState = useCallback(
    (element: T | null) => {
      setState(element)
      if (typeof ref === 'function') {
        ref(element)
      } else if (ref && typeof ref === 'object') {
        /* eslint-disable-next-line no-param-reassign */
        ;(ref as React.RefObject<T | null>).current = element
      }
    },
    [ref, setState],
  )

  return refState
}
