import {useEffect, useRef} from 'react'

import useMergedRef from '../hooks/useMergedRef'

interface AudioProps extends React.HTMLProps<HTMLAudioElement> {
  mediaSrc: string
  ref: React.Ref<HTMLAudioElement>
}

export default function Audio(props: AudioProps): React.ReactNode {
  const {className, children, controls, autoPlay, mediaSrc, ref, ...rest} = props
  const innerRef = useRef<HTMLAudioElement>(null)
  const mergedRef = useMergedRef(ref, innerRef)

  // Handle wontfix Chromium bug in which audio metadata is not set on MediaRecorder Blobs,
  // causing the audio element to prevent seeking/downloading and report incorrect duration
  // https://bugs.chromium.org/p/chromium/issues/detail?id=642012
  useEffect(() => {
    const mediaEle = innerRef.current
    const onTimeUpdateFix = (): void => {
      if (!mediaEle) return

      mediaEle.removeEventListener('timeupdate', onTimeUpdateFix)
      mediaEle.currentTime = 0.01 // must be non-zero
    }

    const onLoadedMetadata = (): void => {
      if (!mediaEle) return

      // Detect the bug
      if (mediaEle.duration === Infinity) {
        // set current time to a value greater than the duration, then set back to start
        mediaEle.currentTime = Number.MAX_SAFE_INTEGER
        mediaEle.addEventListener('timeupdate', onTimeUpdateFix)
      }
    }

    if (!mediaEle) return undefined

    mediaEle.addEventListener('loadedmetadata', onLoadedMetadata)

    return () => {
      mediaEle.removeEventListener('loadedmetadata', onLoadedMetadata)
      mediaEle.addEventListener('timeupdate', onTimeUpdateFix)
    }
  }, [])

  /* eslint-disable jsx-a11y/media-has-caption */
  return (
    <audio
      // unique key to force re-render when src changes so onLoadedMetadata is called
      key={mediaSrc}
      ref={mergedRef}
      className="sr-only"
      controls
      autoPlay={autoPlay}
      {...rest}
    >
      {children}
    </audio>
  )
  /* eslint-enable jsx-a11y/media-has-caption */
}
