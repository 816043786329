import {Button} from '@kensho/neo'
import {use} from 'react'
import {useParams} from 'react-router'

import TranscriptContext from '../../../providers/TranscriptContext'
import downloadFile from '../../../utils/downloadFile'
import snakeCaseDeep from '../../../utils/snakeCaseDeep'

export default function ExportLocalTranscriptButton(): React.ReactNode {
  const {transcript} = use(TranscriptContext)
  const {transcriptId} = useParams()

  return (
    <Button
      icon="DocumentArrowDownIcon"
      onClick={() => {
        downloadFile(`${transcriptId}.json`, JSON.stringify(snakeCaseDeep(transcript)))
      }}
    >
      Export transcript
    </Button>
  )
}
