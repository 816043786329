import {useCallback, useMemo} from 'react'

import useGetTranscript from '../api/useGetTranscript'
import useGetTranscriptMetadata from '../api/useGetTranscriptMetadata'
import {
  APITranscript,
  AsyncStatus,
  Mode,
  TranscriptFormat,
  TranscriptionConfiguration,
  TranscriptMetadata,
} from '../types/types'
import downloadFile from '../utils/downloadFile'
import snakeCaseDeep from '../utils/snakeCaseDeep'
import {prettyText} from '../utils/transcriptUtils'

async function readFile(blob: Blob, readAs: 'text' | 'dataUrl'): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      if (event.target) resolve(event.target.result as string)
    }
    reader.onerror = reject
    switch (readAs) {
      case 'text':
        reader.readAsText(blob)
        break
      case 'dataUrl':
        reader.readAsDataURL(blob)
        break
      default:
        throw new Error(`Invalid readAs: ${readAs}`)
    }
  })
}

type DownloadTranscriptFunction = (
  transcriptId: string,
  downloadFormat: TranscriptFormat,
  options?: {
    transcript?: APITranscript
    transcriptMeta?: TranscriptMetadata
    useDefaultName?: boolean
    transcriptionConfiguration?: TranscriptionConfiguration
  },
) => Promise<void>

export default function useDownloadTranscript(
  mode?: Mode,
): [status: AsyncStatus, downloadTranscript: DownloadTranscriptFunction] {
  const [status, getTranscript] = useGetTranscript(false)
  const getTranscriptMetadata = useGetTranscriptMetadata(false)

  const downloadTranscript = useCallback<DownloadTranscriptFunction>(
    async (
      transcriptId: string,
      downloadFormat: TranscriptFormat,
      options?: {
        transcript?: APITranscript
        transcriptMeta?: TranscriptMetadata
        useDefaultName?: boolean
        transcriptionConfiguration?: TranscriptionConfiguration
      },
    ) => {
      const {transcript, transcriptMeta, useDefaultName, transcriptionConfiguration} = options || {}

      let downloadFilename = transcriptId
      if (useDefaultName && transcriptionConfiguration?.name) {
        downloadFilename = transcriptionConfiguration.name
      } else {
        try {
          downloadFilename = (transcriptMeta || (await getTranscriptMetadata(transcriptId))).name
        } catch (e) {
          // noop, fall back to id
          downloadFilename = transcriptId
        }
      }

      switch (downloadFormat) {
        case 'application/json': {
          if (mode === 'realtime') {
            downloadFile(
              `${downloadFilename}.json`,
              JSON.stringify(snakeCaseDeep(transcript), null, 2),
            )
            break
          }

          const blob = (await getTranscript({
            transcriptId,
            accept: downloadFormat,
            forceBlob: true,
          })) as Blob
          const file = await readFile(blob, 'text')
          downloadFile(`${downloadFilename}.json`, file)
          break
        }
        case 'text/plain': {
          if (mode === 'realtime') {
            downloadFile(`${downloadFilename}.txt`, prettyText(transcript))
            break
          }

          const blob = (await getTranscript({transcriptId, accept: downloadFormat})) as Blob
          const file = await readFile(blob, 'text')
          downloadFile(`${downloadFilename}.txt`, file)
          break
        }
        case 'text/vtt': {
          const blob = (await getTranscript({transcriptId, accept: downloadFormat})) as Blob
          const file = await readFile(blob, 'text')
          downloadFile(`${downloadFilename}.vtt`, file)
          break
        }
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
          const blob = (await getTranscript({transcriptId, accept: downloadFormat})) as Blob
          const file = await readFile(blob, 'dataUrl')
          downloadFile(`${downloadFilename}.docx`, file, false)
          break
        }
        default:
          throw new Error(`Invalid download format: ${downloadFormat}`)
      }
    },
    [getTranscript, getTranscriptMetadata, mode],
  )

  return useMemo(() => [status, downloadTranscript], [status, downloadTranscript])
}
