import Skeleton from '../../../components/Skeleton'
import {TranscriptMetadata} from '../../../types/types'

interface SkeletonTranscriptProps {
  metadata?: TranscriptMetadata
}

export default function SkeletonTranscript(props: SkeletonTranscriptProps): React.ReactNode {
  const {metadata} = props

  return (
    <div className="z-10 w-full max-w-[95vw]">
      {metadata && (
        <div className="absolute z-10 w-full -translate-x-10 translate-y-40 text-center text-2xl font-bold opacity-55">
          {metadata.status !== 'complete' ? 'Transcribing…' : 'Loading…'}
        </div>
      )}
      <div className="flex flex-col gap-6">
        <Skeleton delayMs={150} width={75} height={8} />
        <Skeleton delayMs={200} width="96%" height={8} />
        <Skeleton delayMs={300} width="90%" height={8} />
        <Skeleton delayMs={200} width="94%" height={8} />
        <Skeleton delayMs={700} width="92%" height={8} />
        <Skeleton delayMs={150} width={75} height={8} />
        <Skeleton delayMs={200} width="96%" height={8} />
        <Skeleton delayMs={300} width="90%" height={8} />
        <Skeleton delayMs={200} width="94%" height={8} />
        <Skeleton delayMs={700} width="92%" height={8} />
      </div>
    </div>
  )
}
