import {useMemo} from 'react'
import {useLocation} from 'react-router'

import {ENDPOINTS, MARKETPLACE_IDP} from '../constants'

export interface LoginPaths {
  URL: string
  MARKETPLACE_IDP_URL: string
}

export default function useLoginURL(returnTo?: string): LoginPaths {
  const location = useLocation()
  const loginPaths = useMemo(() => {
    const returnToParam = returnTo || `${window.location.origin}${location.pathname}`
    const defaultURL = `${ENDPOINTS.LOGIN}?${new URLSearchParams({returnTo: returnToParam})}`
    const marketplaceIDP = `${ENDPOINTS.LOGIN}?${new URLSearchParams({
      returnTo: returnToParam,
      idp: MARKETPLACE_IDP,
    })}`

    return {URL: defaultURL, MARKETPLACE_IDP_URL: marketplaceIDP}
  }, [location.pathname, returnTo])

  return loginPaths
}
