import {ReactNode, use, useState} from 'react'

import TranscriptContext from '../../../providers/TranscriptContext'
import {
  BatchMachineTranscriptionConfiguration,
  TranscriptionConfiguration,
} from '../../../types/types'

import BatchConfigForm from './BatchConfigForm'
import {FormState} from './FormState'
import RealtimeConfigForm from './RealtimeConfigForm'

interface TranscriptionConfigProps {
  mediaFile?: File
  setMediaFile: React.Dispatch<React.SetStateAction<File | undefined>>
  transcriptionConfiguration: TranscriptionConfiguration
  setTranscriptionConfiguration: React.Dispatch<React.SetStateAction<TranscriptionConfiguration>>
  audioInputDeviceId?: string
  onAudioInputDeviceChange: (nextAudioDeviceInfoId?: string) => void
  setTranscriptId: (nextTranscriptId?: string) => void
  resetAudioAndTranscript: () => void
}
export default function TranscriptionConfig(props: TranscriptionConfigProps): ReactNode {
  const {
    mediaFile,
    setMediaFile,
    transcriptionConfiguration,
    setTranscriptionConfiguration,
    setTranscriptId,
    resetAudioAndTranscript,
    onAudioInputDeviceChange,
    audioInputDeviceId,
  } = props

  const {mode} = use(TranscriptContext)

  const defaultHotwords =
    'hotwords' in transcriptionConfiguration ? transcriptionConfiguration.hotwords : [] || []

  const [formState, setFormState] = useState<FormState>({
    transcriptName: transcriptionConfiguration.name || '',
    hotwords: new Set<string>(defaultHotwords),
    errors: {},
  })

  return (
    <div className="mx-auto mt-10 h-full w-[550px] overflow-auto px-6 py-5">
      {mode === 'batch' && (
        <>
          <BatchConfigForm
            formState={formState}
            setFormState={setFormState}
            resetAudioAndTranscript={resetAudioAndTranscript}
            setTranscriptId={setTranscriptId}
            mediaFile={mediaFile}
            setMediaFile={setMediaFile}
            setTranscriptionConfiguration={setTranscriptionConfiguration}
            transcriptionConfiguration={
              transcriptionConfiguration as BatchMachineTranscriptionConfiguration
            }
          />
        </>
      )}
      {mode === 'realtime' && (
        <RealtimeConfigForm
          formState={formState}
          setFormState={setFormState}
          onAudioInputDeviceChange={onAudioInputDeviceChange}
          resetAudioAndTranscript={resetAudioAndTranscript}
          setTranscriptId={setTranscriptId}
          setTranscriptionConfiguration={setTranscriptionConfiguration}
          transcriptionConfiguration={transcriptionConfiguration}
          audioInputDeviceId={audioInputDeviceId}
        />
      )}
    </div>
  )
}
