import {DropzoneOptions, useDropzone} from 'react-dropzone'
import clsx from 'clsx'
import {Icon} from '@kensho/neo'

import {VALID_AUDIO_FILE_EXTS, VALID_VIDEO_FILE_EXTS} from './constants'

interface FileUploadProps {
  handleFileDrop: DropzoneOptions['onDrop']
  children: (dropzoneState: ReturnType<typeof useDropzone>) => React.ReactNode
  enableDragState?: boolean
  disabled?: boolean
}

export default function FileUpload({
  children,
  handleFileDrop,
  disabled = false,
  enableDragState = true,
}: FileUploadProps): React.ReactNode {
  const dropzoneState = useDropzone({
    onDrop: handleFileDrop,
    noClick: true,
    noKeyboard: true,
    // Specify both mpeg and mp3 MIME types in order to support both Chrome and Firefox
    accept: {
      'audio/*': VALID_AUDIO_FILE_EXTS,
      'video/*': VALID_VIDEO_FILE_EXTS,
    },
    maxSize: 1000000000, // 1 GB
    useFsAccessApi: false,
    disabled,
  })
  const {getRootProps, getInputProps, isDragActive} = dropzoneState
  return (
    <div className="relative" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive && enableDragState && (
        <div className="absolute inset-0 flex flex-col items-center justify-center gap-2 text-gray-500">
          <Icon icon="DocumentPlusIcon" size="large" />
          Drop file to begin a new transcript
        </div>
      )}
      <div
        className={clsx(
          isDragActive && enableDragState && 'rounded-lg border border-dashed border-gray-300',
        )}
      >
        <div className={clsx(isDragActive && enableDragState ? 'opacity-0' : 'opacity-100')}>
          {children(dropzoneState)}
        </div>
      </div>
    </div>
  )
}
