import {Button, DropdownMenu} from '@kensho/neo'
import {use, useState} from 'react'

import {ScribeError, TranscriptFormat, TranscriptMetadata} from '../../../types/types'
import useDownloadTranscript from '../../../hooks/useDownloadTranscript'
import SiteAnalyticsContext from '../../../providers/SiteAnalyticsContext'
import ErrorDialog from '../../../components/ErrorDialog'
import parseError, {ErrorInput} from '../../../utils/parseError'

import {useTranscriptNameContext} from './TranscriptName/TranscriptNameContext'
import DeleteDialog from './DeleteDialog'

const downloadOptions: {key: TranscriptFormat; name: string}[] = [
  {key: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', name: 'DOCX'},
  {key: 'text/plain', name: 'TXT'},
  {key: 'text/vtt', name: 'VTT'},
  {key: 'application/json', name: 'JSON'},
]

interface TranscriptDropdownMenuProps {
  deleteTranscript: (transcript: TranscriptMetadata) => Promise<void>
  transcript: TranscriptMetadata
}

export default function TranscriptDropdownMenu({
  deleteTranscript,
  transcript,
}: TranscriptDropdownMenuProps): React.ReactNode {
  const {setIsEditing} = useTranscriptNameContext(transcript.id)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [, downloadTranscript] = useDownloadTranscript()
  const [downloadError, setDownloadError] = useState<ScribeError | null>(null)

  const analytics = use(SiteAnalyticsContext)

  return (
    <>
      <DropdownMenu
        items={[
          ...downloadOptions.map(({key, name}) => ({
            id: key,
            label: `Download ${name}`,
            onAction: async () => {
              try {
                await downloadTranscript(transcript.id, key, {transcriptMeta: transcript})
                analytics.sendEvent('download', {file_type: name})
              } catch (error) {
                setDownloadError(parseError(error as ErrorInput))
              }
            },
          })),
          {
            id: 'rename',
            label: 'Rename',
            onAction: () => setIsEditing(true),
          },
          {
            id: 'delete',
            label: 'Delete',
            onAction: () => setIsDeleteDialogOpen(true),
          },
        ]}
      >
        <div className="-my-2">
          <Button aria-label="Actions" icon="EllipsisHorizontalIcon" minimal />
        </div>
      </DropdownMenu>
      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        transcript={transcript}
        deleteTranscript={deleteTranscript}
      />
      {downloadError && (
        <ErrorDialog isOpen onClose={() => setDownloadError(null)} error={downloadError} />
      )}
    </>
  )
}
