import {ButtonLink} from '@kensho/neo'
import {use} from 'react'

import batchImg from '../assets/batch.svg'
import transcriptionInterfaceImg from '../assets/transcription-interface.png'
import websocketImg from '../assets/websocket.svg'
import wordmark from '../assets/wordmark.svg'
import GraphicElement from '../components/GraphicElement'
import Section from '../components/Section'
import {URLS} from '../constants'
import SiteAnalyticsContext from '../providers/SiteAnalyticsContext'
import UserContext from '../providers/UserContext'

import BasePage from './BasePage'

export default function HomePage(): React.ReactNode {
  const {user, _state: userState} = use(UserContext)
  const analytics = use(SiteAnalyticsContext)

  return (
    <BasePage>
      <article>
        <header className="ml-2 max-w-[1000px] lg:mx-auto">
          <img className="mx-0 mb-0 mt-12 inline w-[350px]" src={wordmark} alt="Kensho Scribe" />
          <h1 className="mb-0 text-3xl font-bold lg:mt-7">
            Transcribe Business Audio with unparalleled accuracy, speed, and security.
          </h1>
        </header>
        <Section>
          <div className="order-2 flex-auto basis-1/2 md:order-none">
            <h2 className="m-0 mb-3 text-xl font-bold md:mb-6">
              Transcribe your files without a single line of code with the Scribe Interface.
            </h2>
            <p className="mb-6 text-base lg:mb-14">
              Don’t want to write code? No problem! Drag files into the Scribe Interface and get a
              downloadable transcript with ease.
            </p>
            <ButtonLink
              href="/transcription"
              intent="primary"
              rightIcon="ChevronRightIcon"
              size="large"
            >
              Use Scribe Interface
            </ButtonLink>
          </div>
          <div className="mx-2 flex-auto basis-1/2 md:mx-4">
            <img
              src={transcriptionInterfaceImg}
              alt="LandingPage"
              className="md:order-non order-1 mb-7 w-full shadow md:mb-0"
            />
          </div>
        </Section>

        <Section
          backgroundColor="#F5FAFC"
          title="API Guides & Tutorials"
          description="Step-by-step guides for building tools with the Scribe Batch and Websocket APIs"
        >
          <div className="mx-auto grid grid-cols-1 gap-8 sm:grid-cols-2 sm:py-10 lg:grid-cols-4">
            <GraphicElement
              heading="Authentication Quickstart"
              description="Get started generating API tokens to interact with Scribe"
              label="Get started"
              href="https://docs.kensho.com/authentication/personal-token"
              icon="LockClosedIcon"
            />
            <GraphicElement
              heading="Batch API Quickstart"
              description="Transcribe audio and video files using Scribe’s Batch API"
              label="Get started"
              href="https://docs.kensho.com/scribe/v2/batch-development"
              icon="FolderIcon"
            />
            <GraphicElement
              heading="Real Time Streaming Quickstart"
              description="Transcribe audio in real time using Scribe’s websocket API"
              label="Get started"
              href="https://docs.kensho.com/scribe/v2/real-time-development"
              icon="MicrophoneIcon"
            />
            <GraphicElement
              heading="Video Guides"
              description="Check out our video guides on how to access and use our Scribe solution"
              label="Get started"
              href="https://vimeo.com/showcase/10293126"
              icon="PlayCircleIcon"
            />
          </div>
        </Section>

        <Section
          title="API Reference"
          description="Documentation of each call to and response from the Scribe APIs"
        >
          <div className="flex flex-col gap-4 md:flex-row md:gap-10">
            <div className="flex-1">
              <img src={batchImg} alt="batch file" />
              <h3 className="mb-2 mt-4 md:mt-8">Batch API for Media Files</h3>
              <p className="mb-4 text-base">
                Easily transcribe small or large quantities of business audio and video files using
                the Scribe Batch API for Media Files.
              </p>
              <ButtonLink
                href="https://docs.kensho.com/scribe/v2/batch-api-specification"
                rightIcon="ChevronRightIcon"
                minimal
                intent="primary"
              >
                Explore the Docs
              </ButtonLink>
            </div>

            <div className="flex-1">
              <img src={websocketImg} alt="Streaming Audio" />
              <h3 className="mb-2 mt-4 md:mt-8">Websocket API for Streaming Audio</h3>
              <p className="mb-4 text-base">
                Use the Scribe Websocket API to transcribe streaming audio in real time. You can use
                it to document meetings and calls as they happen, or to provide captions for your
                business conference calls for the hearing impaired.
              </p>
              <ButtonLink
                href="https://docs.kensho.com/scribe/v2/real-time-specification"
                rightIcon="ChevronRightIcon"
                minimal
                intent="primary"
              >
                Explore the Docs
              </ButtonLink>
            </div>
          </div>
        </Section>
        {userState === 'known' && !user?.email && (
          <Section backgroundColor="#F5FAFC">
            <div className="mt-0">
              <h2 className="mb-3 text-xl font-bold">Try Scribe for Free!</h2>
              <p className="mb-6">
                Test Scribe’s transcription on your files with 10 hours for free
              </p>

              <ButtonLink
                href={URLS.FREE_TRIAL}
                intent="primary"
                size="large"
                onClick={() => analytics.sendEvent('request_free_trial')}
              >
                Start a free trial
              </ButtonLink>
            </div>
          </Section>
        )}
      </article>
    </BasePage>
  )
}
