import {ButtonLink, HeadlessLink} from '@kensho/neo'

import logo from '../assets/scribe-logo.svg'
import error from '../assets/error.svg'

import BasePage from './BasePage'

export default function ErrorPage(): React.ReactNode {
  return (
    <BasePage>
      <div className="mx-auto my-24 flex w-full flex-col items-center justify-center sm:w-[675px]">
        <HeadlessLink href="/">
          <img src={logo} alt="Kensho Scribe" />
        </HeadlessLink>
        <h3 className="mb-6 self-start text-lg">Error Code</h3>
        <div className="mb-20 flex gap-6">
          <img className="w-80" src={error} alt="Page Not Found" />
          <div className="flex flex-col gap-3">
            <h1 className="m-0 text-5xl">Oops…</h1>
            <h2 className="m-0 text-3xl">Looks like this page doesn’t exist.</h2>
          </div>
        </div>
        <ButtonLink href="/" intent="primary" size="large">
          Return Home
        </ButtonLink>
      </div>
    </BasePage>
  )
}
