import {cloneDeep} from 'lodash-es'
import {useCallback, use, useMemo} from 'react'

import UserContext from '../providers/UserContext'

/**
 * Wraps fetch to include the access token Authorization header.
 */
export default function useAPIFetchNoAbort(): {
  execute: (url: string, init?: RequestInit) => Promise<Response>
} {
  const {user} = use(UserContext)

  const apiFetch = useCallback(
    (url: string, init: RequestInit = {}): Promise<Response> => {
      const requestInit = cloneDeep(init || {})
      // Set auth headers
      if (!requestInit.headers) requestInit.headers = {}
      ;(requestInit.headers as Record<string, string>).Authorization = `Bearer ${user?.token}`

      return fetch(url, requestInit)
    },
    [user],
  )

  return useMemo(() => ({execute: apiFetch}), [apiFetch])
}
