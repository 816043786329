import {
  init,
  reactRouterV7BrowserTracingIntegration,
  wrapCreateBrowserRouterV7,
} from '@sentry/react'
import {useEffect} from 'react'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router'

init({
  dsn: 'https://caed282b51f543b2b17c7ba2147f6532@sentry.kensho.com/205',
  enabled: import.meta.env.PROD,
  environment: window.location.hostname,
  integrations: [
    reactRouterV7BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
})

export const wrappedCreateBrowserRouter = wrapCreateBrowserRouterV7(createBrowserRouter)
