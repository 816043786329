import {use, useState} from 'react'
import {Button, Dialog} from '@kensho/neo'

import KenshoLogo from '../../assets/kensho-dark-blue.svg'
import SiteAnalyticsContext from '../../providers/SiteAnalyticsContext'

import CookieConsentDialogContents, {CookieConsentOptions} from './CookieConsentDialogContents'

export default function CookieConsentPopup(): React.ReactNode {
  const [isOpen, setIsOpen] = useState(false)

  const analytics = use(SiteAnalyticsContext)

  return (
    <>
      {!analytics.consent.isSet && (
        <div
          className="fixed inset-x-0 bottom-0 z-50 bg-black/90 py-8 md:py-16"
          data-testid="cookie-consent-banner"
        >
          <div className="mx-auto flex max-w-screen-xl flex-col justify-between gap-10 px-8 md:flex-row md:items-center md:gap-20">
            <p className="text-lg leading-loose text-white">
              Kensho uses cookies to improve user experience and site performance and to inform site
              analytics. Where required by applicable law, we will obtain your consent before we
              place any cookies on your device that are not strictly necessary for the functioning
              of our websites. By clicking “Accept all cookies”, you agree to our use of cookies.
              Learn about our cookies and how to modify your preferences in our Cookie Notice. Visit
              our updated{' '}
              <a
                className="underline"
                href="https://www.spglobal.com/en/privacy/privacy-policy-english"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{' '}
              and{' '}
              <a
                className="underline"
                href="https://www.spglobal.com/en/cookie-notice"
                target="_blank"
                rel="noreferrer"
              >
                Cookie Notice
              </a>
              , effective December 30, 2022.
            </p>
            <div className="flex flex-row gap-4 md:flex-col xl:flex-row">
              <Button size="large" onClick={() => setIsOpen(true)}>
                Cookie settings
              </Button>
              <Button
                intent="primary"
                size="large"
                onClick={() => {
                  analytics.setConsent({
                    functional: true,
                    targeting: true,
                    performance: true,
                    isSet: true,
                  })
                }}
              >
                Accept all cookies
              </Button>
            </div>
          </div>
        </div>
      )}
      <Dialog
        backdrop="light"
        title={<img src={KenshoLogo} alt="Kensho Logo" className="w-20" />}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <CookieConsentDialogContents
          /* This submission depends on the current grouping of analytics (boolean gdprConsentCookie and the targeting cookies) - if the set of analytics gets updated,
          we will need to update their data structure and how this submission works accordingly. */
          onClose={() => setIsOpen(false)}
          consentState={analytics.consent}
          changeConsent={(cookieUpdate: Partial<CookieConsentOptions>) => {
            analytics.setConsent({...analytics.consent, ...cookieUpdate})
          }}
        />
      </Dialog>
    </>
  )
}
