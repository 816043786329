import {Drawer, InputGroup} from '@kensho/neo'
import {useMemo, useState} from 'react'

import SHORTCUTS, {Shortcut} from './shortcutRegistration'
import ShortcutSection from './ShortcutSection'

interface ShortcutDrawerProps {
  isOpen: boolean
  onClose: () => void
}

function satisfiesFilter(shortcutName: string, shortcutKey: string[], filter: string): boolean {
  const simpleShortcut = shortcutName.toLowerCase().replace(/[^a-z0-9]+/g, '')
  const simpleShortcutKeys = shortcutKey
    .join('')
    .toLowerCase()
    .replace(/[^a-z0-9?!@#$&()\-`.+,/"]+/g, '')

  const simpleFilter = filter.toLowerCase().replace(/[^a-z0-9?!@#$&()\-`.+,/"]+/g, '')

  return simpleShortcut.includes(simpleFilter) || simpleShortcutKeys.includes(simpleFilter)
}

function mapCategoryDisplayName(categoryName: string): string {
  switch (categoryName) {
    case 'Edit':
      return 'Edit transcript'
    case 'Timeline':
      return 'Edit timeline'
    default:
      return categoryName
  }
}

export default function ShortcutDrawer(props: ShortcutDrawerProps): React.ReactNode {
  const {isOpen, onClose} = props

  const [filter, setFilter] = useState('')

  const displayedShortcuts = useMemo(
    () =>
      Object.entries(SHORTCUTS).reduce(
        (
          acc: {name: string; shortcuts: [string, Shortcut][]}[],
          [categoryName, categoryShortcuts],
        ) => {
          const filteredShortcuts = Object.entries(categoryShortcuts).filter(([, shortcut]) => {
            if ('displayInShortcutPanel' in shortcut && !shortcut.displayInShortcutPanel)
              return false
            return satisfiesFilter(shortcut.description, shortcut.keys, filter)
          })
          if (filteredShortcuts.length > 0)
            return [
              ...acc,
              {name: mapCategoryDisplayName(categoryName), shortcuts: filteredShortcuts},
            ]
          return acc
        },
        [],
      ),
    [filter],
  )

  return (
    <Drawer isOpen={isOpen} onClose={onClose} title="Keyboard Shortcuts">
      <InputGroup
        icon="MagnifyingGlassIcon"
        placeholder="Search shortcuts"
        value={filter}
        onChange={(event) => setFilter(event.target.value)}
      />
      {displayedShortcuts.length > 0 ? (
        <div className="mt-8 flex flex-col gap-10">
          {displayedShortcuts.map((shortcut) => (
            <ShortcutSection name={shortcut.name} shortcuts={shortcut.shortcuts} />
          ))}
        </div>
      ) : (
        <div className="m-4 flex justify-center text-sm text-gray-500">No results found</div>
      )}
    </Drawer>
  )
}
