import {RouteObject} from 'react-router'

import RootPage from './pages/RootPage'
import AccountPage from './pages/AccountPage'
import HomePage from './pages/HomePage'
import TranscriptionPage from './pages/TranscriptionPage'
import RequireAuth from './components/RequireAuth'
import ErrorFallback from './components/ErrorFallback'
import ErrorPage from './pages/ErrorPage'
import TranscriptionsPage from './pages/TranscriptionsPage'

const routes: RouteObject[] = [
  {
    element: <RootPage />,
    errorElement: <ErrorFallback />,
    children: [
      // pages
      {path: '/', element: <HomePage />},
      // protected pages
      {
        element: <RequireAuth />,
        children: [
          {path: '/account', element: <AccountPage />},
          {path: '/transcriptions', element: <TranscriptionsPage />},
          {path: '/transcription', element: <TranscriptionPage />},
          {path: '/transcription/:transcriptId', element: <TranscriptionPage />},
        ],
      },
      // client-side redirects
      {path: '*', element: <ErrorPage />},
    ],
  },
]

export default routes
