import Cookies from 'js-cookie'
import {useMemo, useCallback} from 'react'

import {CookieConsentOptions} from '../components/CookieBanner/CookieConsentDialogContents'
import {CONSENT_COOKIE_DEFAULTS, CONSENT_COOKIE_NAME} from '../constants'
import useCookie from '../hooks/useCookie'

import SiteAnalyticsContext, {
  AnalyticsContextInterface,
  AnalyticsEventDetails,
  AnalyticsProvider,
} from './SiteAnalyticsContext'

const analyticsProviders: AnalyticsProvider[] = [
  {
    name: 'Google Analytics',
    consentRequiredToEnable: {
      functional: false,
      targeting: true,
      performance: true,
      isSet: true,
    },
    enable: (): void => {
      /* @ts-ignore ts(7015) */
      window[`ga-disable-${window.gtagInstanceID}`] = false
      window.gtag('js', new Date())
      window.gtag('config', window.gtagInstanceID, {anonymize_ip: true})
    },
    disable: (): void => {
      /* @ts-ignore ts(7015) */
      window[`ga-disable-${window.gtagInstanceID}`] = true
    },
    sendEvent: (eventName: string, details?: AnalyticsEventDetails): void => {
      window.gtag('event', eventName, details)
    },
    setSessionContext: (name: string, key: string, value: string) => {
      window.gtag('set', name, {[key]: value})
    },
  },
  {
    name: 'Google Tag Manager',
    consentRequiredToEnable: {
      functional: false,
      targeting: true,
      performance: true,
      isSet: true,
    },
    enable: (): void => {
      window.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
        functionality_storage: 'granted',
        personalization_storage: 'granted',
        security_storage: 'granted',
      })
    },
    disable: (): void => {
      window.gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'denied',
      })
    },
  },
  {
    name: 'Microsoft Clarity',
    consentRequiredToEnable: {
      functional: false,
      targeting: true,
      performance: true,
      isSet: true,
    },
    enable: (): void => {
      if (!window.clarityInitialized) {
        window.initClarity()
      }

      window.clarity('consent')
    },
    disable: (): void => {
      if (window.clarityInitialized) {
        window.clarity('stop')
        Cookies.remove('_clck')
        Cookies.remove('_clsk')
      }
    },
    setSessionContext: (_name: string, key: string, value: string) => {
      if (window.clarityInitialized) window.clarity('set', key, value)
    },
  },
  {
    name: 'Plausible',
    consentRequiredToEnable: {
      functional: false,
      targeting: false,
      performance: false,
      isSet: false,
    },
    enable: (): void => {},
    disable: (): void => {},
    sendEvent: (eventName: string, details?: AnalyticsEventDetails): void => {
      window.plausible(eventName, {props: details})
    },
  },
]

const checkConsent = (
  baseConsent: Partial<CookieConsentOptions>,
  testConsent: Partial<CookieConsentOptions>,
): boolean => {
  let modifiedBaseConsent = baseConsent
  if (!baseConsent.isSet) {
    modifiedBaseConsent = {
      functional: false,
      targeting: false,
      performance: false,
      isSet: false,
    }
  }

  return Object.entries(testConsent).every(
    ([key, value]) => !value || modifiedBaseConsent[key as keyof typeof testConsent],
  )
}

/* eslint-enable no-underscore-dangle */

export function submitConsent(consentUpdate: Partial<CookieConsentOptions>): void {
  analyticsProviders.forEach((provider) =>
    checkConsent(consentUpdate, provider.consentRequiredToEnable)
      ? provider.enable()
      : provider.disable(),
  )
}

export default function SiteAnalyticsProvider(props: {children: React.ReactNode}): React.ReactNode {
  const {children} = props

  const [consentCookie, setConsentCookie] = useCookie<CookieConsentOptions>(
    CONSENT_COOKIE_NAME,
    CONSENT_COOKIE_DEFAULTS,
  )

  const setConsent = useCallback(
    (cookie: CookieConsentOptions): void => {
      setConsentCookie(cookie)
      submitConsent(cookie)
    },
    [setConsentCookie],
  )

  const sendEvent = useCallback(
    (
      eventName: string,
      eventDetails?: AnalyticsEventDetails,
      consentFilter: Partial<CookieConsentOptions> = {performance: true},
    ): void => {
      analyticsProviders.forEach((provider) => {
        if (!checkConsent(consentCookie, consentFilter)) return
        if (provider.sendEvent) provider.sendEvent(eventName, eventDetails)
      })
    },
    [consentCookie],
  )

  const setSessionContext = useCallback(
    (
      name: string,
      key: string,
      value: string,
      consentFilter: Partial<CookieConsentOptions> = {performance: true},
    ): void => {
      analyticsProviders.forEach((provider) => {
        if (!checkConsent(consentCookie, consentFilter)) return
        if (provider.setSessionContext) provider.setSessionContext(name, key, value)
      })
    },
    [consentCookie],
  )

  const value = useMemo<AnalyticsContextInterface>(
    () => ({
      consent: consentCookie,
      setConsent,
      sendEvent,
      setSessionContext,
    }),
    [consentCookie, setConsent, sendEvent, setSessionContext],
  )

  return <SiteAnalyticsContext value={value}>{children}</SiteAnalyticsContext>
}
