import {clamp} from 'lodash-es'
import {useCallback, useRef} from 'react'

import {RealtimeClientMessage} from '../../../../types/schemas'
import {REALTIME_TRANSCRIPTION_SAMPLE_RATE} from '../../constants'

import float32ToInt16 from './float32ToInt16'
import littleEndianInt16 from './littleEndianInt16'

const MIN_CHUNK_SIZE = REALTIME_TRANSCRIPTION_SAMPLE_RATE * 1 // seconds of audio data

/** Accumulate audio data until we have a MIN_REALTIME_CHUNK_DURATION second(s) chunk to send for transcription */
export default function useRealtimeBuffer(
  sendMessage: (message: Extract<RealtimeClientMessage, {message: 'AddData'}>) => void,
  onFlush: (sequenceValue: number) => void,
): {
  push: (audioData: number[]) => void
  flush: (all?: boolean) => number
  reset: () => void
} {
  const sequenceRef = useRef(0)
  const rawBufferRef = useRef<number[]>([])

  const flush = useCallback(
    (force = false): number => {
      // skip if there isn't enough data yet
      // force is used when the transcription is being ended, since scriptProcessingNode
      // works in binary buffer sizes it may leave a remainder compared to MIN_CHUNK_SIZE
      if (rawBufferRef.current.length < MIN_CHUNK_SIZE && !force) return sequenceRef.current

      const audioData = rawBufferRef.current.splice(0)
      // transform audio data into what server expects
      const outputData: string = window.btoa(
        audioData.reduce((acc, d) => acc + littleEndianInt16(float32ToInt16(clamp(d, -1, 1))), ''),
      )

      const sequenceNumber = sequenceRef.current
      sendMessage({
        message: 'AddData',
        audio: outputData,
        sequenceNumber,
      })
      sequenceRef.current += 1
      if (onFlush) onFlush(sequenceNumber)
      return sequenceNumber
    },
    [sendMessage, onFlush],
  )

  const push = useCallback(
    (audioData: number[]): void => {
      rawBufferRef.current.push(...audioData)
      flush()
    },
    [flush],
  )

  const reset = useCallback(() => {
    sequenceRef.current = 0
    rawBufferRef.current = []
  }, [])

  return {push, flush, reset}
}
