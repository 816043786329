import {useEffect} from 'react'
import {useLogger} from '@kensho/lumberjack'
import {useRouteError} from 'react-router'

import GenericErrorState from './GenericErrorState'

/**
 * Logs an error caught by React Router.
 *
 * This component should only be used as an `errorElement` in route declarations.
 */
export default function ErrorFallback(): React.ReactNode {
  const log = useLogger()
  const error = useRouteError()

  useEffect(() => {
    log.error(error as Error)
  }, [error, log])

  return <GenericErrorState />
}
