import {Button, ProgressBar} from '@kensho/neo'
import {uniq, uniqueId} from 'lodash-es'
import {useCallback, use, useEffect, useRef, useState} from 'react'
import {AnimatePresence, motion} from 'framer-motion'

import useUploadBatch from '../../../api/useUploadBatch'
import ErrorDialog from '../../../components/ErrorDialog'
import MediaFileInput from '../../../components/MediaFileInput'
import SiteAnalyticsContext from '../../../providers/SiteAnalyticsContext'
import TranscriptContext from '../../../providers/TranscriptContext'
import {
  BatchMachineTranscriptionConfiguration,
  ScribeError,
  TranscriptionConfiguration,
} from '../../../types/types'
import prettyFileSize from '../../../utils/prettyFileSize'
import {VALID_AUDIO_FILE_EXTS, VALID_VIDEO_FILE_EXTS} from '../constants'

import {FormState} from './FormState'
import TranscriptName from './TranscriptName'

interface BatchConfigFormProps {
  mediaFile?: File
  setMediaFile: React.Dispatch<React.SetStateAction<File | undefined>>
  transcriptionConfiguration: BatchMachineTranscriptionConfiguration
  setTranscriptId: (nextTranscriptId?: string) => void
  resetAudioAndTranscript: () => void
  setTranscriptionConfiguration: React.Dispatch<React.SetStateAction<TranscriptionConfiguration>>
  formState: FormState
  setFormState: React.Dispatch<React.SetStateAction<FormState>>
}

const MAX_SIZE = 1e9 // 1 GB
const VALID_FILE_EXTS = uniq([...VALID_AUDIO_FILE_EXTS, ...VALID_VIDEO_FILE_EXTS]).join(', ')

function validateBatchFile(file?: File): string | undefined {
  if (!file) return `File is required`
  if (file.size > MAX_SIZE) return `File is too large. Max size ${prettyFileSize(MAX_SIZE)}`
  const extension = file.name.match(/.+(\..+)$/)?.[1]
  if (!extension || !VALID_FILE_EXTS.includes(extension.toLowerCase()))
    return `Invalid File Type. Supported file types: ${VALID_FILE_EXTS}`
  return undefined
}

export default function BatchConfigForm(props: BatchConfigFormProps): React.ReactNode {
  const {
    mediaFile,
    setMediaFile,
    setTranscriptId,
    resetAudioAndTranscript,
    transcriptionConfiguration,
    setTranscriptionConfiguration,
    formState,
    setFormState,
  } = props

  const {dispatch: transcriptContextDispatch} = use(TranscriptContext)
  const [scribeError, setScribeError] = useState<ScribeError>()
  const [uploadBatchStatus, uploadBatch, cancelUploadBatch] = useUploadBatch()
  const uploadRequestID = useRef<string>(undefined)

  const analytics = use(SiteAnalyticsContext)

  const startTranscription = useCallback((): void => {
    if (!mediaFile) return
    if (Object.values(formState.errors).some((error) => error)) return
    const newTranscriptionConfiguration = {
      ...transcriptionConfiguration,
      name: formState.transcriptName,
      hotwords: formState.hotwords,
    }

    setTranscriptionConfiguration(newTranscriptionConfiguration)
    transcriptContextDispatch({type: 'setMode', mode: 'batch'})
    setTranscriptId()

    const requestId = uniqueId('batch-upload')
    uploadRequestID.current = requestId

    uploadBatch({file: mediaFile, options: newTranscriptionConfiguration})
      .then((id: string) => {
        if (requestId !== uploadRequestID.current) return // noop
        setTranscriptId(id)
      })
      .catch((e: DOMException | ScribeError) => {
        if (requestId !== uploadRequestID.current) return // noop

        if (e instanceof DOMException && e.name === 'AbortError') return // noop
        setScribeError(e instanceof DOMException ? {type: 'unknown'} : e)

        transcriptContextDispatch({
          type: 'setStage',
          stage: 'PRE_TRANSCRIPTION',
        })
      })
      .finally(() => {
        if (requestId === uploadRequestID.current) {
          uploadRequestID.current = undefined
        }
      })
  }, [
    mediaFile,
    transcriptContextDispatch,
    setTranscriptId,
    uploadBatch,
    transcriptionConfiguration,
    formState,
    setTranscriptionConfiguration,
  ])

  useEffect(
    () => () => {
      uploadRequestID.current = undefined
    },
    [],
  )

  return (
    <>
      <ErrorDialog
        isOpen={!!scribeError}
        error={scribeError}
        onClose={() => setScribeError(undefined)}
      />
      <AnimatePresence mode="wait">
        {uploadBatchStatus === 'pending' ? (
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 0.5, ease: 'easeInOut'}}
            key="uploadingStatus"
          >
            <div className="mt-10">
              <div className="mb-3 mt-2 text-lg">Uploading…</div>
              <ProgressBar />
              <div className="mb-4 mt-2 text-sm text-gray-500">
                This may take several minutes depending on file size and connection speed.
              </div>
              <Button
                onClick={() => {
                  uploadRequestID.current = undefined
                  analytics.sendEvent('cancel_batch_upload')
                  cancelUploadBatch()
                  transcriptContextDispatch({
                    type: 'setStage',
                    stage: 'PRE_TRANSCRIPTION',
                  })
                }}
              >
                Cancel
              </Button>
            </div>
          </motion.div>
        ) : (
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.5, ease: 'easeInOut'}}
            key="selectBatchFile"
          >
            <div className="flex flex-col gap-4">
              <div className="text-lg font-semibold">Start a new transcription</div>
              <MediaFileInput
                label="File"
                isInvalid={!!formState.errors?.file}
                errorText={formState.errors?.file}
                value={mediaFile}
                description={`Supported file types: ${VALID_FILE_EXTS}`}
                onChange={(file) => {
                  setMediaFile(file)
                  setFormState((prev) => ({
                    ...prev,
                    transcriptName: file?.name || prev.transcriptName,
                    errors: {...prev.errors, file: validateBatchFile(file)},
                  }))
                }}
              />
              <TranscriptName
                error={formState.errors.transcriptName}
                setFormState={setFormState}
                transcriptName={formState.transcriptName}
              />
            </div>

            <div className="mt-10 flex flex-col gap-10">
              <div className="flex justify-end gap-4">
                <Button
                  onClick={() => {
                    analytics.sendEvent('cancel_batch')
                    resetAudioAndTranscript()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={Object.values(formState.errors).some((error) => error) || !mediaFile}
                  intent="primary"
                  onClick={() => {
                    analytics.sendEvent('transcribe_batch')
                    startTranscription()
                  }}
                >
                  Transcribe
                </Button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
