import {Button, DropdownMenu} from '@kensho/neo'
import {use, useState} from 'react'
import {useParams} from 'react-router'

import ErrorDialog from '../../../components/ErrorDialog'
import useDownloadTranscript from '../../../hooks/useDownloadTranscript'
import SiteAnalyticsContext from '../../../providers/SiteAnalyticsContext'
import TranscriptContext from '../../../providers/TranscriptContext'
import {Mode, ScribeError, TranscriptFormat, TranscriptionConfiguration} from '../../../types/types'
import parseError, {ErrorInput} from '../../../utils/parseError'

interface DownloadTranscriptButtonProps {
  mode?: Mode
  transcriptionConfiguration?: TranscriptionConfiguration
  // callback for when the download has been initiated
  onDownload: () => void
}

/**
 * Trigger a download of the transcript in the specified format.
 * Downloaded file will be named <transcript_id>.<mime_type>
 */
export default function DownloadTranscriptButton(
  props: DownloadTranscriptButtonProps,
): React.ReactNode {
  const {mode, transcriptionConfiguration, onDownload} = props
  const [downloadError, setDownloadError] = useState<ScribeError>()
  const {transcriptId} = useParams()
  const {transcript} = use(TranscriptContext)
  const [status, downloadTranscript] = useDownloadTranscript(mode)
  const analytics = use(SiteAnalyticsContext)

  async function downloadFormat(format: TranscriptFormat): Promise<void> {
    analytics.sendEvent('download', {file_type: format})
    if (!transcriptId) {
      setDownloadError({type: 'transcriptNotFound'})
      return
    }
    try {
      await downloadTranscript(transcriptId, format, {
        transcript,
        useDefaultName: mode === 'realtime',
        transcriptionConfiguration,
      })
      onDownload()
    } catch (error) {
      setDownloadError(parseError(error as ErrorInput))
    }
  }

  return (
    <>
      {downloadError && (
        <ErrorDialog isOpen error={downloadError} onClose={() => setDownloadError(undefined)} />
      )}
      <DropdownMenu
        items={[
          {
            id: 'docx',
            label: 'DOCX',
            disabled: !transcript || mode === 'realtime',
            onAction: () =>
              downloadFormat(
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              ),
          },
          {
            id: 'txt',
            label: 'TXT',
            disabled: !transcript,
            onAction: () => downloadFormat('text/plain'),
          },
          {
            id: 'vtt',
            label: 'VTT',
            disabled: !transcript || mode === 'realtime',
            onAction: () => downloadFormat('text/vtt'),
          },
          {
            id: 'json',
            label: 'JSON',
            disabled: !transcript,
            onAction: () => downloadFormat('application/json'),
          },
        ]}
      >
        <Button
          disabled={!transcriptId || status === 'pending'}
          icon={status === 'pending' ? undefined : 'ArrowDownTrayIcon'}
          rightIcon="ChevronDownIcon"
          intent="primary"
        >
          {status === 'pending' ? 'Downloading…' : 'Download'}
        </Button>
      </DropdownMenu>
    </>
  )
}
