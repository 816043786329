import {useCallback, useMemo} from 'react'
import clsx from 'clsx'

import {APITranscript, Mode, Stage} from '../../types/types'
import getSpeakerColor from '../../utils/getSpeakerColor'
import prettyDuration from '../../utils/prettyDuration'
import {getDuration} from '../../utils/transcriptUtils'

interface TimelineProps {
  hasMedia: boolean
  transcript?: APITranscript
  mode?: Mode
  stage: Stage
  currentTime?: number // seconds
  duration?: number // seconds
  onSeek: (time: number) => void
}

export default function Timeline(props: TimelineProps): React.ReactNode {
  const {hasMedia, transcript, currentTime, duration, mode, stage, onSeek} = props

  // seconds
  const transcriptDuration = useMemo(() => {
    if (!transcript) return 0
    return getDuration(transcript) / 1000
  }, [transcript])

  const onTimelineClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!transcriptDuration || !hasMedia) return
      const {left, width: eleWidth} = event.currentTarget.getBoundingClientRect()
      const position = (event.pageX - left) / eleWidth
      onSeek((duration || transcriptDuration) * position)
    },
    [onSeek, duration, transcriptDuration, hasMedia],
  )

  return (
    <div className="bottom-0 flex flex-row items-center gap-4 px-4">
      {/* native audio/video element is mounted for accessible media controls */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className={clsx(
          'relative flex h-5 w-full cursor-pointer items-center',
          !hasMedia && 'cursor-default',
        )}
        onClick={onTimelineClick}
      >
        <div className="relative flex h-1 w-full bg-gray-300">
          {!!transcriptDuration &&
            (transcript?.sliceMeta || []).map((slice) => (
              <div
                className="absolute z-[1] h-full flex-none"
                key={`${slice.startMs}:${slice.durationMs}:${slice.accuracy}`}
                style={{
                  background: getSpeakerColor(slice.speakerId, 'transparent'),
                  width: `${(slice.durationMs / 1000 / transcriptDuration) * 100}%`,
                  left: `${(slice.startMs / 1000 / transcriptDuration) * 100}%`,
                }}
              />
            ))}
        </div>
        {!(mode === 'realtime' && stage === 'TRANSCRIPTION') &&
          hasMedia &&
          duration &&
          currentTime !== undefined && (
            <div
              style={{
                left: `calc(${(currentTime / duration) * 100}% - 8px)`,
              }}
              className="absolute z-[2] h-4 w-4 rounded-full bg-brand-700"
            />
          )}
      </div>
      {currentTime !== undefined && !(mode === 'realtime' && stage === 'PRE_TRANSCRIPTION') && (
        <span className="min-w-28 whitespace-nowrap text-sm leading-4 text-black">
          <span data-testid="current-time">{prettyDuration(currentTime)}</span> /{' '}
          {prettyDuration(duration || transcriptDuration || currentTime)}
        </span>
      )}
    </div>
  )
}
