import {use, useMemo} from 'react'
import {HeadlessButton, Popover} from '@kensho/neo'

import {APITranscriptToken, TranscriptSelection} from '../../../types/types'
import SpellCheckContext from '../../../providers/SpellCheckContext'
import {DICTIONARIES} from '../../account/dictionaries'

import ReviewSpelling from './review/ReviewSpelling'

interface Props {
  sliceIndex: number
  tokenIndex: number
  token: APITranscriptToken
  onClose: () => void
}

function TokenPopover(props: Props): React.ReactNode {
  const {sliceIndex, tokenIndex, token, onClose} = props
  const spellCheck = use(SpellCheckContext)
  const dictionaryResource = DICTIONARIES.find((d) => d.langCode === spellCheck.langCode)

  const transcriptSelection = useMemo<TranscriptSelection>(
    () => ({
      type: 'Range',
      start: {
        type: 'token',
        sliceIndex,
        tokenIndex,
        textOffset: 0,
      },
      end: {
        type: 'token',
        sliceIndex,
        tokenIndex,
        textOffset: token.transcript.length,
      },
    }),
    [sliceIndex, tokenIndex, token],
  )

  return (
    <div className="pointer-events-none absolute bottom-0 left-0 right-0 top-0">
      <Popover
        isOpen
        onOpenChange={onClose}
        position="bottom-start"
        content={
          <div className="flex h-[260px] w-[380px] flex-col">
            <header className="flex h-12 items-center justify-between border-b p-4">
              <h2>Spelling ({dictionaryResource?.label} dictionary)</h2>
            </header>
            <ReviewSpelling
              transcriptSelection={transcriptSelection}
              onComplete={() => onClose()}
            />
          </div>
        }
      >
        <HeadlessButton />
      </Popover>
    </div>
  )
}

export default TokenPopover
