interface SectionProps {
  children: React.ReactNode
  backgroundColor?: string
  title?: React.ReactNode
  description?: string
}

export default function Section(props: SectionProps): React.ReactNode {
  const {children, backgroundColor = 'transparent', title, description} = props

  return (
    <section className="w-full overflow-hidden px-0 py-12 lg:py-8" style={{backgroundColor}}>
      <div className="mx-auto mb-0 mt-3 max-w-5xl">
        {title && (
          <header className="mx-3 mb-5 mt-0 lg:mb-0">
            <h2 className="text-xl font-bold">{title}</h2>
            {description && <p className="mb-8">{description}</p>}
          </header>
        )}
        <div className="flex flex-col flex-wrap sm:mx-3 sm:my-0 sm:flex-row sm:flex-nowrap">
          {children}
        </div>
      </div>
    </section>
  )
}
