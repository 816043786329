import {useLogger} from '@kensho/lumberjack'
import {memo, useCallback, use, useEffect} from 'react'
import {Icon} from '@kensho/neo'
import {AnimatePresence, motion} from 'framer-motion'

import useKeyboardShortcut from '../../../hooks/useKeyboardShortcut'
import {UpdateTranscriptSelectionType} from '../../../hooks/useTranscriptSelection'
import SHORTCUTS from '../../../shortcuts/shortcutRegistration'
import {
  APITranscript,
  APITranscriptToken,
  Stage,
  TranscriptMetadata,
  TranscriptSelection,
} from '../../../types/types'
import copyText from '../../../utils/copyText'
import formatCase from '../../../utils/formatCase'
import {OperationAction, produceOperation} from '../../../utils/transcriptPatchUtils'
import {
  compareTokenPosition,
  getTextFromTranscriptSelection,
  getTokenRange,
  getTranscriptSelection,
  isTimeInSlice,
} from '../../../utils/transcriptUtils'
import {TranscriptPermissionsContext} from '../TranscriptPermissionsProvider'
import {KHighlight} from '../../../highlights/types'
import {TOTAL_HEIGHT} from '../../../highlights/needs-review/constants'

import {useDispatchEditOperation} from './DispatchEditOperationProvider'
import SkeletonTranscript from './SkeletonTranscript'
import TranscriptSlice from './TranscriptSlice'

interface InputEvent<T> extends React.FormEvent<T> {
  data: string
}

interface TranscriptProps {
  metadata?: TranscriptMetadata
  showPlayControls: boolean
  transcript?: APITranscript
  stage: Stage
  currentTimeMs?: number
  paused: boolean
  onClickToken: (token: APITranscriptToken) => void
  seekMedia: (options: {timeSeconds: number; play?: boolean; scroll?: boolean}) => void
  undo: () => void
  redo: () => void
  updateTranscriptSelection: UpdateTranscriptSelectionType
  transcriptSelection: TranscriptSelection | null
  onEditOperationError: (error: Error, action: OperationAction) => void
  isTranscriptFocused: boolean
  setIsTranscriptFocused: React.Dispatch<React.SetStateAction<boolean>>
  transcriptSizeRef: (node: HTMLElement | null) => void
  visibleBatches: Record<string, boolean>
  transcriptRef: React.RefObject<HTMLDivElement | null>
  kHighlights: KHighlight[]
  setActiveHighlightId: React.Dispatch<React.SetStateAction<string | undefined>>
}

function Transcript(props: TranscriptProps): React.ReactNode {
  const {
    metadata,
    transcript,
    onEditOperationError,
    stage,
    currentTimeMs = 0,
    onClickToken,
    seekMedia,
    showPlayControls,
    undo,
    redo,
    transcriptSelection,
    updateTranscriptSelection,
    setIsTranscriptFocused,
    transcriptSizeRef,
    visibleBatches,
    transcriptRef,
    kHighlights,
    setActiveHighlightId,
  } = props
  const log = useLogger()
  const {dispatchEditOperation} = useDispatchEditOperation()
  const {transcriptPermissions} = use(TranscriptPermissionsContext)

  useKeyboardShortcut(SHORTCUTS.Edit.undo.keys, () => {
    if (!transcriptPermissions.edit) return
    undo()
  })

  useKeyboardShortcut(SHORTCUTS.Edit.redo.keys, () => {
    if (!transcriptPermissions.edit) return
    redo()
  })

  useKeyboardShortcut(
    SHORTCUTS.Edit.toggleCapitalization.keys,
    () => {
      if (!transcriptPermissions.edit) return
      if (!transcript) return
      if (!transcriptSelection) return
      const {start, end} = transcriptSelection
      if (!start || !end) return

      const startSliceIndex = start.sliceIndex
      const endSliceIndex = end.sliceIndex
      let {tokenIndex: startTokenIndex} = start
      let {tokenIndex: endTokenIndex} = end

      // noop
      // foo[ ]bar
      if (
        start.type === 'token-space' &&
        end.type === 'token-space' &&
        start.tokenIndex === end.tokenIndex &&
        start.textOffset === 0 &&
        end.textOffset === 1
      ) {
        return
      }

      if (start.type === 'token-space' && start.textOffset > 0) {
        if (startTokenIndex + 1 < transcript.sliceMeta[startSliceIndex].tokenMeta.length) {
          // if the selection starts on a space adjust the token index to the next natural token
          // because the token-space index points to the preceding token index
          // foo |bar -> foo |Bar
          startTokenIndex += 1
          endTokenIndex += 1
        } else if (endSliceIndex === startSliceIndex && endTokenIndex === startTokenIndex) {
          // the selection is after the end of a slice which has no following natural token
          return
        }
      } else if (
        start.type === 'token-space' &&
        start.textOffset === 0 &&
        end.type !== 'token-space'
      ) {
        // foo[ b]ar -> foo[ B]ar
        startTokenIndex += 1
      }

      // if the selection ends on a space adjust the token index to the next natural token
      // because the token-space index points to the preceding token index
      // foo |bar -> foo |Bar
      if (
        end.type === 'token-space' &&
        end.textOffset > 0 &&
        (startSliceIndex !== endSliceIndex || startTokenIndex !== endTokenIndex) &&
        endTokenIndex + 1 < transcript.sliceMeta[endSliceIndex].tokenMeta.length
      ) {
        endTokenIndex += 1
      }

      const action: OperationAction = {
        type: 'replace-tokens',
        data: getTokenRange(transcript, {
          startToken: {sliceIndex: startSliceIndex, tokenIndex: startTokenIndex},
          endToken: {sliceIndex: endSliceIndex, tokenIndex: endTokenIndex},
        }),
      }

      const shouldCapitalize = /^[a-z]/.test(action.data[0].token.transcript)

      action.data = action.data.map((indexedToken) => ({
        ...indexedToken,
        token: {
          ...indexedToken.token,
          transcript: formatCase(indexedToken.token.transcript, shouldCapitalize),
        },
      }))

      const operation = produceOperation({
        action,
        transcript,
        transcriptSelection: getTranscriptSelection(),
        onError: onEditOperationError,
        log,
      })
      if (!operation) return

      dispatchEditOperation(operation)
    },
    {preventDefault: true},
  )

  const populateEmptyTranscript = useCallback(() => {
    if (!transcript) return
    if (!transcriptPermissions.edit) return

    const operation = produceOperation({
      action: {type: 'insert-text', data: ' '},
      transcript,
      transcriptSelection: {
        type: 'Caret',
        start: null,
        end: null,
      },
      log,
    })
    if (!operation) return

    dispatchEditOperation(operation)
  }, [transcript, transcriptPermissions, log, dispatchEditOperation])

  // keep transcript selection state in sync when window selection changes
  useEffect(() => {
    const handleSelection = (): void => {
      const selection = getTranscriptSelection()
      updateTranscriptSelection(selection, false, false)
    }
    document.addEventListener('selectionchange', handleSelection)
    return () => {
      document.removeEventListener('selectionchange', handleSelection)
    }
  }, [updateTranscriptSelection])

  // keep isTranscriptFocused state in sync
  useEffect(() => {
    const transcriptEle = transcriptRef.current

    const onFocusIn = (): void => setIsTranscriptFocused(true)
    const onFocusOut = (): void => setIsTranscriptFocused(false)

    transcriptEle?.addEventListener('focusin', onFocusIn)
    transcriptEle?.addEventListener('focusout', onFocusOut)

    return () => {
      transcriptEle?.removeEventListener('focusin', onFocusIn)
      transcriptEle?.removeEventListener('focusout', onFocusOut)
    }
  }, [setIsTranscriptFocused, transcriptRef])

  return (
    <div
      className="whitespace-pre-wrap"
      ref={(r) => {
        transcriptRef.current = r
        transcriptSizeRef(r)
      }}
    >
      <AnimatePresence mode="wait">
        {transcript ? (
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 0.5, ease: 'easeInOut'}}
            key="finalTranscript"
          >
            {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
            <div
              data-clarity-mask="True"
              className="min-w-[752px] outline-none"
              style={{marginBottom: `${TOTAL_HEIGHT}px`}}
              data-testid="transcript"
              contentEditable
              suppressContentEditableWarning
              spellCheck={false}
              onClick={() => {
                if (!transcript) return
                const currentTranscriptSelection = getTranscriptSelection()
                if (!currentTranscriptSelection) return
                const clickedHighlights = kHighlights.filter((highlight) => {
                  const {ranges, annotation} = highlight
                  if (!annotation) return false
                  if (annotation.label !== 'needs-review-highlight') return false

                  const isClicked = ranges.some((range) => {
                    if (
                      !currentTranscriptSelection.start ||
                      !currentTranscriptSelection.end ||
                      !range.start ||
                      !range.end
                    )
                      return false
                    return (
                      compareTokenPosition(currentTranscriptSelection.start, range.start) !==
                        'before' &&
                      compareTokenPosition(currentTranscriptSelection.end, range.end) !== 'after'
                    )
                  })
                  return isClicked
                })
                if (clickedHighlights.length > 0) {
                  const smallestClickedHighlight = clickedHighlights
                    .map((highlight) => ({
                      highlight,
                      length: getTextFromTranscriptSelection(transcript, highlight.ranges[0])
                        .length,
                    }))
                    .sort((a, b) => a.length - b.length)[0].highlight
                  setActiveHighlightId(smallestClickedHighlight.annotation?.id)
                } else {
                  setActiveHighlightId(undefined)
                }
              }}
              onKeyDown={(event) => {
                if (!transcriptPermissions.edit) {
                  event.preventDefault()
                  return
                }
                if (
                  event.key === 'a' &&
                  event.metaKey &&
                  !event.shiftKey &&
                  !event.altKey &&
                  transcript
                ) {
                  const lastSliceIndex = Math.max(transcript.sliceMeta.length - 1, 0)
                  const lastTokenIndex = Math.max(
                    transcript.sliceMeta[lastSliceIndex].tokenMeta.length - 1,
                    0,
                  )
                  updateTranscriptSelection(
                    {
                      type: 'Range',
                      start: {type: 'token', sliceIndex: 0, tokenIndex: 0, textOffset: 0},
                      end: {
                        type: 'token-space',
                        sliceIndex: lastSliceIndex,
                        tokenIndex: lastTokenIndex,
                        textOffset: 0,
                      },
                    },
                    false,
                    // explicitly setting force = false, because we don't render offscreen tokens that are not part of selection
                    // so have to wait for effect to update selection and render those, otherwise we try too early
                    // and return null because elements don't exist in DOM
                    false,
                  )
                }

                if (['Backspace', 'Delete', 'Enter'].includes(event.key)) {
                  event.preventDefault()

                  if (!transcript) return

                  let action: OperationAction | undefined
                  if (event.key === 'Backspace' || event.key === 'Delete') {
                    action = {
                      type: 'delete-text',
                      direction: event.key === 'Backspace' ? 'backward' : 'forward',
                    }
                  }
                  if (event.key === 'Enter') {
                    action = {type: 'split-slice'}
                  }
                  if (!action) return

                  const operation = produceOperation({
                    action,
                    transcript,
                    transcriptSelection: getTranscriptSelection(),
                    onError: onEditOperationError,
                    log,
                  })
                  if (!operation) return

                  dispatchEditOperation(operation)
                }
              }}
              onBeforeInput={(event) => {
                event.preventDefault()

                if (!transcript) return
                if (!transcriptPermissions.edit) return
                if (!transcriptSelection) return

                const operation = produceOperation({
                  action: {type: 'insert-text', data: (event as InputEvent<HTMLDivElement>).data},
                  transcript,
                  transcriptSelection,
                  onError: onEditOperationError,
                  log,
                })
                dispatchEditOperation(operation)
              }}
              onCopy={(event) => {
                event.preventDefault()

                if (!transcript) return
                if (!transcriptSelection) return

                // update the transcript selection first
                // in case window.getSelection() is not synced with transcriptSelection
                // this is only expected to happen after cmd+a due to our LOD token rendering
                // since copyText is giong to use winow.getSelection() to restore
                updateTranscriptSelection(transcriptSelection, false, true)

                const text = getTextFromTranscriptSelection(transcript, transcriptSelection)
                copyText(text)
              }}
              onCut={(event) => {
                event.preventDefault()

                if (!transcript) return
                if (!transcriptPermissions.edit) return
                if (!transcriptSelection) return

                // manually copy cut text to clipboard because we don't want cut event to modify dom
                const text = getTextFromTranscriptSelection(transcript, transcriptSelection)
                copyText(text)

                const operation = produceOperation({
                  action: {type: 'delete-text'},
                  transcript,
                  transcriptSelection,
                  onError: onEditOperationError,
                  log,
                })
                dispatchEditOperation(operation)
              }}
              onPaste={(event) => {
                event.preventDefault()

                if (!transcript) return
                if (!transcriptPermissions.edit) return
                if (!transcriptSelection) return

                const operation = produceOperation({
                  action: {type: 'insert-text', data: event.clipboardData.getData('text/plain')},
                  transcript,
                  transcriptSelection,
                  onError: onEditOperationError,
                  log,
                })

                dispatchEditOperation(operation)
              }}
            >
              {stage === 'POST_TRANSCRIPTION' && transcript?.sliceMeta.length === 0 ? (
                <div
                  className="flex items-center justify-center gap-2 italic text-gray-400"
                  onClick={() => {
                    if (!transcriptPermissions.edit) return
                    populateEmptyTranscript()
                  }}
                  onKeyDown={(event) => {
                    if (!transcriptPermissions.edit) return
                    if (event.key === 'Enter') {
                      populateEmptyTranscript()
                    }
                  }}
                >
                  <Icon icon="PencilSquareIcon" />
                  Transcript is empty. {transcriptPermissions.edit ? 'Click to add.' : ''}
                </div>
              ) : (
                (transcript?.sliceMeta || []).map((slice, i) => {
                  const hasSelectionBoundary =
                    i === transcriptSelection?.start?.sliceIndex ||
                    i === transcriptSelection?.end?.sliceIndex

                  return (
                    <TranscriptSlice
                      hasSelectionBoundary={hasSelectionBoundary}
                      showPlayControls={showPlayControls}
                      key={`${slice.startMs}:${slice.durationMs}:${slice.accuracy}`}
                      stage={stage}
                      slice={slice}
                      sliceIndex={i}
                      currentTimeMs={
                        stage === 'POST_TRANSCRIPTION' &&
                        currentTimeMs !== 0 &&
                        isTimeInSlice(currentTimeMs, slice)
                          ? currentTimeMs
                          : undefined
                      }
                      onClickToken={onClickToken}
                      seekMedia={seekMedia}
                      visibleBatches={visibleBatches}
                    />
                  )
                })
              )}
            </div>
          </motion.div>
        ) : (
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.5, ease: 'easeInOut'}}
            key="loadingTranscript"
          >
            <div className="ml-32 min-w-[752px] outline-none">
              <SkeletonTranscript metadata={metadata} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default memo(Transcript)
