import {useLogger} from '@kensho/lumberjack'
import {Button, Tooltip, useToaster} from '@kensho/neo'
import {useCallback, use} from 'react'
import {useDropzone} from 'react-dropzone'

import {APITranscript, TranscriptSelection} from '../../../types/types'
import camelCaseDeep from '../../../utils/camelCaseDeep'
import {OperationAction, produceOperation} from '../../../utils/transcriptPatchUtils'
import {useDispatchEditOperation} from '../transcript/DispatchEditOperationProvider'
import {TranscriptPermissionsContext} from '../TranscriptPermissionsProvider'

interface Props {
  onEditOperationError: (error: Error, action: OperationAction) => void
  transcriptSelection: TranscriptSelection | null
  transcript: APITranscript
  transcriptId?: string
}

export default function ImportTranscriptButton(props: Props): React.ReactNode {
  const {onEditOperationError, transcript, transcriptId, transcriptSelection} = props
  const {transcriptPermissions} = use(TranscriptPermissionsContext)
  const {dispatchEditOperation} = useDispatchEditOperation()
  const toaster = useToaster()
  const log = useLogger()
  const handleImport = useCallback(
    async (acceptedFiles: File[]) => {
      let nextTranscript: APITranscript
      try {
        const file = acceptedFiles[0]
        const reader = new FileReader()
        const readJSONPromise = new Promise((resolve) => {
          reader.onload = (event) => {
            if (!event.target) throw new Error('Empty result')
            const data = camelCaseDeep(JSON.parse(event.target.result as string))
            resolve(data)
          }
        })
        reader.readAsText(file)
        nextTranscript = (await readJSONPromise) as APITranscript
      } catch (error) {
        log.error(error as Error)
        toaster.show({
          label: `Failed to import ${acceptedFiles[0].name} `,
          intent: 'danger',
        })
        return
      }

      log.info('Import transcript from JSON', {transcriptId})

      const operation = produceOperation({
        action: {type: 'replace-transcript', data: nextTranscript},
        transcript,
        transcriptSelection,
        onError: onEditOperationError,
        log,
      })
      dispatchEditOperation(operation)
    },
    [
      transcript,
      transcriptId,
      transcriptSelection,
      onEditOperationError,
      log,
      dispatchEditOperation,
      toaster,
    ],
  )
  const {getInputProps, getRootProps, open} = useDropzone({
    onDropAccepted: handleImport,
    accept: {
      'application/json': ['.json'],
    },
    multiple: false,
    noDrag: true,
    useFsAccessApi: false,
    disabled: !transcriptPermissions.edit,
  })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Tooltip content="Import from JSON" position="bottom">
        <Button
          aria-label="Import from JSON"
          icon="DocumentArrowUpIcon"
          disabled={!transcriptPermissions.edit}
          minimal
          intent="primary"
          onClick={() => open()}
        />
      </Tooltip>
    </div>
  )
}
