import {useToaster} from '@kensho/neo'
import {useEffect, useState} from 'react'

import {WebSocketConnectionStatus} from '../hooks/useTypedWebSocket'

type ReconnectionState = 'reconnecting' | 'reconnected' | 'failed'

export default function useReconnectionNotifications(
  status: WebSocketConnectionStatus,
  shouldConnect: boolean,
): void {
  const toaster = useToaster()
  const [reconnection, setReconnection] = useState<null | ReconnectionState>(null)
  const [hasEverConnected, setHasEverConnected] = useState(false)

  if (!hasEverConnected && status === 'open') setHasEverConnected(true)

  if (hasEverConnected && status === 'connecting' && reconnection !== 'reconnecting') {
    setReconnection('reconnecting')
  }

  if (status === 'open' && reconnection === 'reconnecting') setReconnection('reconnected')

  if (status === 'closed' && (reconnection === 'reconnecting' || reconnection === null))
    setReconnection('failed')

  if (!shouldConnect && !!reconnection) {
    setReconnection(null)
  }

  if (!shouldConnect && hasEverConnected) setHasEverConnected(false)

  useEffect(() => {
    let toastId: string | undefined
    switch (reconnection) {
      case 'reconnecting':
        toastId = toaster.show({
          label: 'Reconnecting...',
          intent: 'primary',
          timeout: null,
        })
        break

      case 'reconnected':
        toastId = toaster.show({label: 'Reconnected!', intent: 'success'})
        break
      case 'failed':
        toastId = toaster.show({
          label: 'Reconnection failed',
          intent: 'danger',
        })
        break
      default:
        break
    }
    return () => {
      if (toastId) toaster.dismiss(toastId)
    }
  }, [reconnection, toaster])
}
