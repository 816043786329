import {Button, DropdownMenu} from '@kensho/neo'
import {useState} from 'react'

import {Mode} from '../../types/types'

import UnloadWarningDialog from './UnloadWarningDialog'

const REALTIME_TRANSCRIPTION_URL = '/transcription?mode=realtime'

interface NewTranscriptMenuProps {
  onNewTranscriptConfig: (configMode: Mode) => void
  resetAudioAndTranscript: () => void
  showUnsavedTranscriptWarning: boolean
  setTranscriptDownloaded: React.Dispatch<React.SetStateAction<boolean>>
}

export default function NewTranscriptMenu({
  onNewTranscriptConfig,
  showUnsavedTranscriptWarning,
  setTranscriptDownloaded,
}: NewTranscriptMenuProps): React.ReactNode {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <UnloadWarningDialog
        isOpen={isOpen}
        content={
          <div className="flex flex-col gap-4">
            <p>Are you sure you want to leave this page and discard this transcript?</p>
            <p>
              Scribe does not save real time transcriptions, so be sure to download your transcript
              text before closing your session, or it will be lost permanently.
            </p>
          </div>
        }
        title="Leave page and discard this transcript?"
        primaryButtonProps={{
          intent: 'danger',
          children: 'Leave & discard transcript',
          onClick: () => {
            window.location.href = REALTIME_TRANSCRIPTION_URL
          },
        }}
        secondaryButtonProps={{
          children: 'Cancel & stay on page',
          onClick: () => setIsOpen(false),
        }}
        onClose={() => setIsOpen(false)}
      />

      <div className="flex w-full flex-col">
        <DropdownMenu
          items={[
            {
              id: 'batch',
              label: 'Upload',
              onAction() {
                onNewTranscriptConfig('batch')
              },
            },
            {
              id: 'realtime',
              label: 'Record',
              onAction() {
                if (showUnsavedTranscriptWarning) {
                  setIsOpen(true)
                  setTranscriptDownloaded(true)
                } else {
                  window.location.href = REALTIME_TRANSCRIPTION_URL
                }
              },
            },
          ]}
        >
          <Button icon="PlusCircleIcon" rightIcon="ChevronDownIcon">
            Create new
          </Button>
        </DropdownMenu>
      </div>
    </>
  )
}
