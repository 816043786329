import {cloneDeep} from 'lodash-es'
import {useCallback, use, useMemo, useRef} from 'react'

import UserContext from '../providers/UserContext'

/**
 * Wraps fetch to include the access token Authorization header
 * as well as aborting of previous invocations
 */
export default function useAPIFetch(abortDuplicateRequests = true): {
  execute: (url: string, init?: RequestInit) => Promise<Response>
  cancel: () => void
} {
  const {user} = use(UserContext)
  const abortControllerRef = useRef<AbortController>(undefined)

  const apiFetch = useCallback(
    (url: string, init: RequestInit = {}): Promise<Response> => {
      // cancel an in-flight request
      if (abortControllerRef.current && abortDuplicateRequests) abortControllerRef.current.abort()

      abortControllerRef.current = new AbortController()
      const requestInit = cloneDeep(init || {})
      requestInit.signal = abortControllerRef.current.signal
      // set auth headers
      if (!requestInit.headers) requestInit.headers = {}
      ;(requestInit.headers as Record<string, string>).Authorization = `Bearer ${user?.token}`

      return fetch(url, requestInit)
    },
    [user, abortDuplicateRequests],
  )

  const cancel = useCallback(() => {
    abortControllerRef.current?.abort()
  }, [])

  return useMemo(() => ({execute: apiFetch, cancel}), [apiFetch, cancel])
}
