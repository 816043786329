import clsx from 'clsx'

interface SkeletonProps {
  width?: number | string
  height?: number
  animate?: boolean
  delayMs?: number
}

function getCssDimension(value: number | string = 'auto'): string {
  if (typeof value === 'number') return `${value}px`
  return value
}

export default function Skeleton(props: SkeletonProps): React.ReactNode {
  const {width = '100%', height, animate = true, delayMs} = props

  return (
    <div
      className={clsx(
        'mx-0 my-1 inline-block h-4 animate-pulse rounded bg-gray-200',
        animate && 'animate-half-fade',
      )}
      style={{
        width: getCssDimension(width),
        height: getCssDimension(height),
        animationDelay: `${delayMs}ms`,
      }}
    >
      {/* blank character gives container height of inherited font-size */}
      &nbsp;
    </div>
  )
}
