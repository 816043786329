import {Outlet} from 'react-router'
import {KenshoLogotype} from '@kensho/branding'
import {use} from 'react'

import UserContext from '../providers/UserContext'

import SignInDialog from './SignInDialog'

export default function RequireAuth(): React.ReactNode {
  const {user, _state: userState} = use(UserContext)

  if (user) return <Outlet />

  return (
    <div className="flex h-screen items-center justify-center">
      <SignInDialog isOpen={userState === 'known'} />
      <KenshoLogotype height="50%" width="50%" />
    </div>
  )
}
