import {useState, useEffect, useCallback} from 'react'

function useBatchVisibility(
  scrollContainerRef: React.RefObject<HTMLDivElement | null>,
  height: number,
): {
  visibleBatches: Record<string, boolean>
  updateVisibleBatches: () => void
} {
  const [visibleBatches, setVisibleBatches] = useState<Record<string, boolean>>({})

  const updateVisibleBatches = useCallback(() => {
    const {current: scrollContainer} = scrollContainerRef
    if (!scrollContainer) return

    const viewportRect = scrollContainer.getBoundingClientRect()
    const batches = document.querySelectorAll('.token-batch')

    function comparePosition(batch: Element): 'above' | 'below' | 'within' {
      const batchRect = batch.getBoundingClientRect()
      const overscan = 256
      // the top could be adjusted to account for this sticky header but we want some overscan anyway
      if (batchRect.bottom + overscan < viewportRect.top) {
        return 'above'
      }
      if (batchRect.top - overscan > viewportRect.bottom) {
        return 'below'
      }
      return 'within'
    }

    function findAnyVisibleBatchIndex(): number {
      let left = 0
      let right = batches.length - 1
      while (left <= right) {
        const mid = Math.floor((left + right) / 2)
        const batch = batches[mid]
        const comparison = comparePosition(batch)

        if (comparison === 'within') {
          return mid
        }
        if (comparison === 'above') {
          left = mid + 1
        } else {
          right = mid - 1
        }
      }

      return -1
    }

    function findVisibleBatches(visibleBatchIndex: number): Record<string, boolean> {
      const map: Record<string, boolean> = {[batches[visibleBatchIndex].id]: true}
      for (let i = visibleBatchIndex - 1; i >= 0; i -= 1) {
        if (comparePosition(batches[i]) === 'within') {
          map[batches[i].id] = true
        } else {
          break
        }
      }
      for (let i = visibleBatchIndex + 1; i < batches.length; i += 1) {
        if (comparePosition(batches[i]) === 'within') {
          map[batches[i].id] = true
        } else {
          break
        }
      }
      return map
    }

    const anyVisibleBatchIndex = findAnyVisibleBatchIndex()
    if (anyVisibleBatchIndex >= 0) {
      setVisibleBatches(findVisibleBatches(anyVisibleBatchIndex))
    }
  }, [scrollContainerRef])

  useEffect(() => {
    updateVisibleBatches()
  }, [height, updateVisibleBatches])

  useEffect(() => {
    window.addEventListener('resize', updateVisibleBatches)
    return () => {
      window.removeEventListener('resize', updateVisibleBatches)
    }
  }, [updateVisibleBatches])

  return {visibleBatches, updateVisibleBatches}
}

export default useBatchVisibility
