import {use} from 'react'
import {camelCase} from 'lodash-es'
import {Patch} from 'immer'

import useMultiplayerContext from '../../../hooks/useMultiplayerContext'
import TranscriptContext from '../../../providers/TranscriptContext'
import camelCaseDeep from '../../../utils/camelCaseDeep'

export default function useRemoteTranscriptChanges(): void {
  const {dispatch} = use(TranscriptContext)
  const {sessionId} = useMultiplayerContext((message) => {
    if (message.type !== 'patch') return
    if (message.payload.sessionId === sessionId) return

    // three differences that we need to transform for patches on the client vs the server:
    // 1. path keys need to be converted to camelCase
    // 2. paths need to be converted to arrays instead of JSON pointer strings
    // 3. values need to be converted to camelCase
    const immerPatches = message.payload.patches.map(
      (patch) =>
        camelCaseDeep({
          ...patch,
          path: patch.path
            .split('/')
            .slice(1)
            .map((part) => (!Number(part) ? camelCase(part) : part)),
        }) as Patch,
    )

    dispatch({
      type: 'patch',
      operation: {
        patches: immerPatches,
        inversePatches: [],
        beforeSelection: null,
        afterSelection: null,
      },
      undoable: false,
    })
  })
}
